import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { generateBreadcrumbs } from '_helpers/generateBreadcrumbs'
import { SupervisorAccount } from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'
import { translate } from '_helpers/translate'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { AccessDenied } from 'pages/AccessDenied'
import { List } from './List'
import { Addto } from './Addto'
import { Editto } from './Editto'
import { routePath } from '_constants/routes.constants.js'

const routes = () => [
  {
    title: translate('T_PROFESSIONS_LIST'),
    type: Route,
    render: () => <List />,
    path: routePath.profession.list(''),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_PROFESSION_EDIT'),
    type: Route,
    component: Editto,
    path: routePath.profession.edit(':id', ''),
    exact: true,
  },
  {
    title: translate('T_PROFESSION_ADD'),
    type: Route,
    component: Addto,
    path: routePath.profession.add(''),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_PROFESSIONS'),
    type: Redirect,
    from: '/',
    to: routePath.profession.list(''),
    exact: true,
  },

  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

const menu = () => [routes()[0]]

export const Professions = (props) => {
  const { match, location } = props

  if (hasRole(authConstants.ROLE_SUPER_ADMIN)) {
    return (
      <Page
        menu={prefixPaths(match.url, menu())}
        routes={generateRoutes(
          prefixPaths(match.url, routes(), ['path', 'from', 'to'])
        )}
        breadcrumbs={generateBreadcrumbs(
          location.pathname,
          prefixPaths(match.url, routes(), ['path', 'from'])
        )}
      />
    )
  } else {
    return <AccessDenied />
  }
}
