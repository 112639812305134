import { authHeader } from '_helpers/authHeader'
import { tokenVerify } from '_helpers/tokenVerify'
import { translate } from '_helpers/translate'

const login = (email, password) => fetchToken(email, password)

const refresh = () => fetchToken()

const fetchToken = (email, password) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(email && password ? { login: email, password } : {}),
  }

  if (!email && !password) {
    requestOptions.headers = {
      ...requestOptions.headers,
      ...authHeader(),
    }
  }

  const url = `${process.env.REACT_APP_API_ENTRYPOINT}/${
    email && password ? 'security/login' : 'security/refresh'
  }`

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      const { token } = response
      if (tokenVerify(token)) return token
      else return Promise.reject(translate('Bad credentials.'))
    })
}

const handleResponse = (response) =>
  response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && translate(data.message)) || response.statusText
      return Promise.reject(error)
    }

    return data
  })

export const authService = {
  login,
  refresh,
}
