import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { requestActions } from '_actions'
import { buildUrl } from './buildUrl'
import { isObjectEmpty } from './isObjectEmpty'
import { excludeInnerNullObjects } from './excludeInnerNullObjects'

export const useFetch = (url, options, args, queryParams = {}) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    isLoading: false,
    data: null,
    error: null,
    dataLength: null,
  })
  useEffect(
    () => {
      const abortController = new AbortController()
      const optionsNew = { ...options, ...{ signal: abortController.signal } }

      if (Object.keys(queryParams).indexOf('refresh')) {
        delete queryParams.refresh
      }

      const urlNew = buildUrl(url, {
        queryParams: excludeInnerNullObjects(queryParams),
      })
      const fetchData = async () => {
        dispatch(requestActions.start(urlNew))
        setState((state) => ({ ...state, isLoading: true }))
        try {
          const res = await fetch(urlNew, optionsNew)
          const data = await res.json()

          if (data.code === 401) {
            alert(data.code + ' ' + data.message)
            return
          }

          dispatch(requestActions.success())

          setState((state) => ({
            ...state,
            data,
            dataLength: data.length,
            isLoading: false,
          }))
        } catch (e) {
          if (!abortController.signal.aborted) {
            dispatch(requestActions.failure(e))
            setState((state) => ({ ...state, isLoading: false, error: true }))
          }
        }
      }
      fetchData()
      return () => {
        abortController.abort()
      }
    },
    // eslint-disable-next-line
    isObjectEmpty(queryParams) ? args : [...args, queryParams]
  )

  return { state, setState }
}
