import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { generateBreadcrumbs } from '_helpers/generateBreadcrumbs'
import { SupervisorAccount, ChildCare } from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'
import { translate } from '_helpers/translate'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { AccessDenied } from 'pages/AccessDenied'
import { List } from './List'
import { Addto } from './Addto'
import { Editto } from './Editto'

import { List as TherapiesList } from './Therapies/List'
import { Addto as TherapiesAddto } from './Therapies/Addto'
import { Editto as TherapiesEditto } from './Therapies/Editto'

import { List as InquiriesList } from './Inquiries/List'
import { View as InquiriesViewto } from './Inquiries/View'

import { routePath } from '_constants/routes.constants.js'

const routes = () => [
  {
    title: translate('T_CHILDS_LIST'),
    type: Route,
    render: () => <List />,
    path: routePath.childs.list(''),
    exact: true,
    icon: ChildCare,
  },
  {
    title: translate('T_CHILD_EDIT'),
    type: Route,
    component: Editto,
    path: routePath.childs.edit(':childId', ''),
    exact: true,
  },
  {
    title: translate('T_CHILD_ADD'),
    type: Route,
    component: Addto,
    path: routePath.childs.add(''),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_CHILDS'),
    type: Redirect,
    from: '/',
    to: routePath.childs.list(''),
    exact: true,
  },

  // CHILD THERPAIES
  {
    title: translate('T_CHILD_THERAPIES_LIST'),
    type: Route,
    render: ({ match }) => <TherapiesList match={match} />,
    path: routePath.childTherapies.list(':childId', '/list/therapies'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_CHILD_THERAPY_EDIT'),
    type: Route,
    component: TherapiesEditto,
    path: routePath.childTherapies.edit(':childId', ':id', '/list/therapies'),
    exact: true,
  },
  {
    title: translate('T_CHILD_THERAPY_ADD'),
    type: Route,
    component: TherapiesAddto,
    path: routePath.childTherapies.add(':childId', '/list/therapies'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_CHILD_THERAPIES'),
    type: Redirect,
    from: '/',
    to: routePath.childTherapies.list(':childId', '/list/therapies'),
    exact: true,
  },

  // CHILD INQUIRIES
  {
    title: translate('T_CHILD_INQUIRIES_LIST'),
    type: Route,
    render: ({ match }) => <InquiriesList match={match} />,
    path: routePath.childInquiries.list(':childId', '/list/inquiries'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_CHILD_INQUIRIES'),
    type: Redirect,
    from: '/',
    to: routePath.childInquiries.list(':childId', '/list/inquiries'),
    exact: true,
  },
  {
    title: translate('T_CHILD_INQUIRY_VIEW'),
    type: Route,
    component: InquiriesViewto,
    path: routePath.childInquiries.view(':childId', ':id', '/list/inquiries'),
    exact: true,
  },

  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

const menu = () => [routes()[0]]

export const Childs = (props) => {
  const { match, location } = props

  if (
    hasRole(authConstants.ROLE_SUPER_ADMIN) ||
    hasRole(authConstants.ROLE_CHILD_ADMIN) ||
    hasRole(authConstants.ROLE_CHILD)
  ) {
    return (
      <Page
        menu={prefixPaths(match.url, menu())}
        routes={generateRoutes(
          prefixPaths(match.url, routes(), ['path', 'from', 'to'])
        )}
        breadcrumbs={generateBreadcrumbs(
          location.pathname,
          prefixPaths(match.url, routes(), ['path', 'from'])
        )}
      />
    )
  } else {
    return <AccessDenied />
  }
}
