export const translations = {
  pl: {
    ROLE_SUPER_ADMIN: 'super admin',
    ROLE_MANAGER: 'kierownik',
    ROLE_THERAPIST: 'terapeuta',
    ROLE_PARENT: 'rodzic',

    T_ACCEPTED: 'zaakceptowany',
    T_ACCEPTED_USER_LABEL: 'użytkownik zaakceptowany',
    T_ACTIVE: 'aktywny',

    T_ANSWERS_LIST: 'Lista odpowiedzi',

    T_ADD_GAME_PRACTICE_STAGE: 'dodaj etap',
    T_ADD_SUPER_ADMIN: 'dodaj superadmina',
    T_ADD_SAVE: 'zapisz',

    T_ADDRESS_LABEL: 'Adres ośrodka',
    T_ADDRESS_HINT: 'Wpisz adres ośrodka zdrowia',

    T_ADMINISTRATION: 'Administracja',
    T_AGE_MIN_LABEL: 'Minimalny wiek',
    T_AGE_MIN_HINT: 'Należy podać minimalny wiek',
    T_AGE_MAX_LABEL: 'Maksymalny wiek',
    T_AGE_MAX_HINT: 'Należy podać maksymalny wiek',

    T_ALL: 'wszystko',

    T_BIRTHDATE: 'Data urodzenia',
    T_BIRTH_DATE_LABEL: 'Data urodzenia',
    T_BIRTH_DATE_HINT: 'Podaj date urodzenia',

    T_CANCEL: 'Anuluj',
    T_CATEGORY: 'Kategoria',
    T_CLOSE: 'Zamknij',
    T_CITY: 'Miasto',
    T_CITY_LABEL: 'Miasto',
    T_CITY_HINT: 'Wybierz miasto',

    T_CHILD: 'dziecko',
    T_CHILD_ADD: 'dodaj dziecko',
    T_CHILD_EDIT: 'Edycja dziecka',
    T_CHILDS: 'Dzieci',
    T_CHILDS_LIST: 'Lista dzieci',
    T_CHILD_FIRST_NAME: 'Imię dziecka',
    T_CHILD_INQUIRIES_LIST: 'Ankiety dziecka',
    T_CHILD_INQUIRY_CREATED_DATE: 'Data wypełnienia ankiety',
    T_CHILD_INQUIRY_VIEW: ' Ankieta',
    T_CHILD_LAST_NAME: 'Nazwisko dziecka',

    T_CHILD_PHOTO_UPLOAD_DIALOG_TITLE: 'Wgrywanie zdjęcia dziecka',
    T_CHILD_PHOTO_PLEASE_SELECT_FILE: 'Wybierz plik ze zdjęciem',

    T_CHILD_THERAPIES_LIST: 'Lista terapii dziecka',
    T_CHILD_THERAPY_EDIT: 'Edycja terapii dziecka',

    T_CONFIG: 'Konfiguracja',
    T_CONFIG_SETTINGS: 'Ustawienia',
    
    T_CONFIG_GAMES_ACCESS_MESSAGE_LABEL: 'Gry - info o płatnym dostępie',
    T_CONFIG_GAMES_ACCESS_MESSAGE_HINT: 'Wyświetla sie przy próbie dostępu do gier terapeutycznych z konta bez opłaconego abonamentu',
    
    
    
    
    T_CONTENT: 'treść',
    T_CONTENT_HINT: 'Wpisz treść',
    T_CONTENT_LABEL: 'Treść',

    T_DESCRIPTION: 'Opis',
    T_DESCRIPTION_LABEL: 'Opis',
    T_DESCRIPTION_HINT: 'Wpisz opis',

    T_EMAIL: 'E-mail',
    T_EMAIL_LABEL: 'E-mail',
    T_EMAIL_HINT: 'Podaj adres email',

    T_FILE_UPLOAD_LABEL: 'Wgraj plik',
    T_FILE_UPLOAD_HINT: 'Wybierz plik multimedialny',

    T_FIRSTNAME_LABEL: 'Imię',
    T_FIRST_NAME_LABEL: 'Imię',

    T_FIRSTNAME_HINT: 'Podaj imię',
    T_FIRST_NAME_HINT: 'Podaj imię',

    T_FORGOT_PASSWORD: 'Nie pamiętam hasła',

    T_GAME: 'Gra',
    T_GAME_EDIT: 'Edycja gry',
    T_GAME_ELEMENT_ADD: 'Dodaj element gry',
    T_GAME_ELEMENT_EDIT: 'Edycja elementu gry',
    T_GAME_ELEMENT_LABEL: 'Element',
    T_GAME_ELEMENT_HINT: 'Wybierz element',
    T_GAME_ELEMENTS_LIST: 'Elementy gier',

    T_GAME_EMOTION_LABEL: 'Emocja',
    T_GAME_EMOTION_LIST: 'Emocje',
    T_GAME_EMOTION_HINT: 'Wybierz emocję',
    T_GAME_EMOTION_ADD: 'Dodaj emocję',

    T_GAME_LABEL: 'Gra',
    T_GAME_HINT: 'Wybierz grę',

    T_GAME_PRACTICE_CATEGORY_LABEL: 'Kategoria',
    T_GAME_PRACTICE_CATEGORY_HINT: 'Wybierz kategorię',
    T_GAME_PRACTICE_TITLE: 'Tytuł ćwiczenia',
    T_GAME_PRACTICE_ADD: 'Dodaj ćwiczenie',
    T_GAME_PRACTICE_EDIT: 'Edycja ćwiczenia',
    T_GAME_PRACTICE_OF_GAME: 'Ćwiczenie w grze',
    T_GAME_PRACTICE_STAGE_CONFIGURATION: 'Konfiguracja etapu gry',
    T_GAME_PRACTICE_STAGE_PREVIEW: 'Podgląd',
    T_GAME_PRACTICE_STAGES_LIST: 'Lista etapów gry',
    T_GAME_PRACTICES_LIST: 'Ćwiczenia',

    T_GAME_PRACTICE_CATEGORY_LIST: 'Kategorie ćwiczeń',
    T_GAME_PRACTICE_CATEGORY_ADD: 'Utwórz kategorię ćwiczeń',
    T_GAME_PRACTICE_CATEGORY_EDIT: 'Edycja kategorii ćwiczeń',

    T_GAME_TITLE: 'Tytuł gry',

    T_GAMES: 'Gry',
    T_GAMES_AND_GAME_PRACTICES: 'Gry i ćwiczenia',
    T_GAMES_LIST: 'Gry',

    T_GENDER: 'płeć',
    T_GENDER_BOY: 'chłopiec',
    T_GENDER_GIRL: 'dziewczynka',
    T_GENDER_HINT: 'Podaj płeć dziecka',
    T_GENDER_LABEL: 'Płeć',

    T_HEALTH_CENTER: 'Ośrodek',
    T_HEALTH_CENTER_ADD: 'dodaj ośrodek',
    T_HEALTH_CENTER_EDIT: 'Edycja ośrodka',
    T_HEALTH_CENTER_CHILDS_LIST: 'Lista dzieci w ośrodku',
    T_HEALTH_CENTER_THERAPIES_LIST: 'Lista terapii w ośrodku',
    T_HEALTH_CENTERS: 'Ośrodki',
    T_HEALTH_CENTERS_LIST: 'Lista ośrodków',

    T_HEALTH_CENTER_LABEL: 'Ośrodek',
    T_HEALTH_CENTER_HINT: 'Wybierz ośrodek',

    T_ID: 'ID',
    T_IDNAME: 'Identyfikator',

    T_INACTIVE: 'nieaktywny',
    T_INQUIRIES: 'Ankiety',
    T_INQUIRIES_ASSIGNED: 'Przypisane do ankiety',
    T_INQUIRIES_LIST: 'Lista ankiet',
    T_INQUIRY_ADD: 'Dodaj ankietę',
    T_INQUIRY_EDIT: 'Edycja ankiety',
    T_INQUIRY_AGE_MIN: 'Minimalny wiek',
    T_INQUIRY_AGE_MAX: 'Maksymalny wiek',
    T_INQUIRY_ANSWER_ADD: 'Dodaj odpowiedź',
    T_INQUIRY_ANSWERS_LIST: 'Lista odpowiedzi',
    T_INQUIRY_LABEL : 'Etykieta',
    T_INQUIRY_PRACTICE_ADD: 'Dodaj ćwiczenie do ankiety',
    T_INQUIRY_PRACTICES_EDIT: 'Edycja ćwiczenia w ankiecie',
    T_INQUIRY_PRACTICES_LIST: 'Lista ćwiczeń w ankiecie',
    T_INQUIRY_QUESTION_ADD: 'Dodaj pytanie',
    T_INQUIRY_QUESTION_EDIT: 'Edycja pytania',
    T_INQUIRY_QUESTIONS_LIST: 'Lista pytań w ankiecie',
    T_INQUIRY_STAT: 'Status ankiety',
    T_INQUIRY_STATUS_LABEL: 'Status ankiety',
    T_INQUIRY_TITLE: 'Tytuł ankiety',
    T_INQUIRY_VERDICT_EDIT: 'Edycja diagnozy',
    T_INQUIRY_VERDICT_ADD: 'Dodaj diagnozę',
    T_INQUIRY_VERDICTS_LIST: 'Lista diagnoz',

    T_IS_ACCEPTED: 'Zaakceptowany',

    T_LASTNAME_LABEL: 'Nazwisko',
    T_LAST_NAME_LABEL: 'Nazwisko',
    T_LASTNAME_HINT: 'Podaj nazwisko',
    T_LAST_NAME_HINT: 'Podaj nazwisko',
    T_LOGIN_TITLE: 'Logowanie',
    T_LOGIN_ERROR: 'błędny login',

    T_NAME: 'Nazwa',
    T_NAME_LABEL: 'Nazwa',
    T_NAME_HINT: 'Podaj nazwę',
    T_NO_PRACTICES_ADDED: 'Nie dodano ćwiczeń',
    T_NOT_ACCEPTED: 'niezaakceptowany',
    T_NOT_ASSIGNED: 'nieprzypisany',

    T_PARENT: 'Rodzic',
    T_PARENT_CHILDS_LIST: 'Lista dzieci rodzica',
    T_PARENT_CONFIRMED: 'Potwierdzone przez rodzica',
    T_PARENT_CONFIRMED_LABEL: 'Potwierdzenie przez rodzica',
    T_PASSWORD_ERROR: 'błędne hasło',
    T_PASSWORD_RESET: 'Resetowanie hasła',
    T_PASSWORD_TITLE: 'Ustaw hasło',
    T_PAYMENT_VALID_UNTIL_LABEL: 'Premium ważne do',
    T_PAYMENT_VALID_UNTIL_HINT: 'Podaj datę',
    
    
    T_PHONE: 'Telefon',

    T_PRACTICE_CATEGORY: 'Kategoria ćwiczeń',
    T_PRACTICE_LABEL: 'Ćwiczenie',
    T_PRACTICE_HINT: 'Wybierz ćwiczenie',
    T_PRACTICE_TITLE: 'Tytuł ćwiczenia',
    T_PRACTICES_COUNT : 'Ilość ćwiczeń',
    T_PRACTICES_LIST : 'Lista ćwiczeń',

    T_PROVINCE: 'Województwo',
    T_PROVINCE_LABEL: 'Województwo',
    T_PROVINCE_HINT: 'Wybierz województwo',
    T_PROFESSION: 'Specjalizacja',
    T_PROFESSION_ADD: 'Dodaj specjalizację',
    T_PROFESSION_EDIT: 'Edycja specjalizacji',
    T_PROFESSIONS: 'Specjalizacje',
    T_PROFESSIONS_LABEL: 'Specjalizacje',
    T_PROFESSIONS_HINT: 'Podaj specjalizacje',
    T_PROFESSIONS_LIST: 'Lista specjalizacji',

    T_QUESTION: 'Pytanie',
    T_QUESTION_HINT: 'Wpisz pytanie',
    T_QUESTION_LABEL: 'Pytanie',
    T_QUESTIONS_LIST: 'Lista pytań',

    T_REMOVE_PHOTO: 'Usuń',
    T_REMOVE_PHOTO_CONFIRM_TITLE: 'Usuwanie zdjęcia',
    T_REMOVE_PHOTO_CONFIRM_TEXT: 'Czy na pewno usunąć zdjęcie?',

    T_ROLE_SUPER_ADMIN: 'super admin',
    T_ROLE_MANAGER: 'kierownik',
    T_ROLE_THERAPIST: 'terapeuta',
    T_ROLE_PARENT: 'rodzic',

    T_ROLES: 'Role',

    T_SAVE: 'Zapisz',
    T_SAVING: 'Zapis',
    T_SAVING_TITLE: 'Zapisano poprawnie',

    T_SAVING_ERROR: 'Błąd',
    T_SAVING_ERROR_TITLE: 'Wystąpił błąd zapisu',

    T_SCORE: 'Ilość punktów',
    T_SCORE_LABEL: 'Ilość punktów',
    T_SCORE_HINT: 'Podaj ilość punktów',
    T_SCORE_MIN: 'Minimalna ilość punktów',
    T_SCORE_MIN_LABEL: 'Minimalna ilość punktów',
    T_SCORE_MIN_HINT: 'Podaj minimalną ilość punktów dla tej diagnozy',
    T_SCORE_MAX: 'Maksymalna ilość punktów',
    T_SCORE_MAX_LABEL: 'Maksymalna ilość punktów',
    T_SCORE_MAX_HINT: 'Podaj maksymalną ilość punktów dla tej diagnozy',

    T_SHOW_HEALTH_CENTER_LIST: 'Pokaż listę ośrodków',

    T_STAGES_COUNT: 'Ilość etapów',
    T_STAT: 'Status',
    T_STATUS: 'status',
    T_SUBMIT: 'Zapisz',
    T_SUBMIT_UPLOAD: 'Wgraj',
    T_SUPER_ADMIN_LIST: 'Lista superadminów',

    T_TEMPLATES_COUNT: 'Ilość szablonów',

    T_THERAPIST: 'Terapeuta',
    T_THERAPIST_CONFIRMED: 'Potwierdzenie terapeuty',
    T_THERAPIST_CONFIRMED_LABEL: 'Potwierdzone przez terapeutę',
    T_THERAPIST_FIRST_NAME: 'Imię terapeuty',
    T_THERAPIST_LAST_NAME: 'Nazwisko terapeuty',
    T_THERAPIST_THERAPIES_LIST: 'Terapie terapeuty',

    T_THERAPIST_THERAPY_EDIT: 'Edycja terapii terapeuty',

    T_THERAPY_START_DATE: 'Data rozpoczęcia terapii',

    T_THUMB: 'Miniatura',

    T_TIME_SECONDS_LIMIT_LABEL: 'Limit czasu (sekundy)',
    T_TIME_SECONDS_LIMIT_HINT: 'Podaj limit czasu (0-bez limitu)',

    T_TITLE: 'Tytuł',
    T_TITLE_LABEL: 'Tytuł',
    T_TITLE_HINT: 'Wpisz tytuł',

    T_TYPE: 'Typ',
    T_TYPE_HINT: 'Wybierz rodzaj',
    T_TYPE_LABEL: 'Typ',

    T_TYPE_PHOTO: 'Photo',
    T_TYPE_AUDIO: 'Audio',
    T_TYPE_VIDEO: 'Video',

    T_USER_EDIT: 'Edycja użytkownika',
    T_USERS: 'Użytkownicy',
    T_USERS_LIST: 'Lista użytkowników',

    T_USS: 'Serwisy klientów',
    T_USS_LIST: 'Lista',
    T_USS_EDIT: 'Edycja serwisu',
    T_USS_ADD: 'Dodaj serwis',
    T_USS_SETTINGS: 'Ustawienia serwisu',
    T_USS_ADMIN_LIST: 'Główni administratorzy serwisu',
    T_USS_EDIT_ADMIN: 'Edycja administratora',
    T_USS_ADD_ADMIN: 'Dodawanie administratora',

    T_VERDICT_SCORE: 'Ilość punktów',
    T_VERDICT_TITLE: 'Diagnoza',
    T_VERDICT_TEXT: 'Opis diagnozy',
    T_VERDICTS_LIST: 'Lista diagnoz',

    T_WWW_LABEL: 'Strona www',
    T_WWW_HINT: 'Wpisz adres strony www',

    Error: 'Błąd',
    'Bad credentials.': 'Niepoprawne dane',
    'This value should not be blank.': 'Wartość nie może być pusta',
    'This value is too short. It should have 6 characters or more.':
      'Wartość powinna mieć conajmniej 6 znaków',
    'This value is already used.': 'Ta wartość jest już zajęta, wybierz inną',
    'Bad resetting password token': 'Twój link wygasł',
    'Expired resetting password token': 'Twój link wygasł',
    'User with this login doesnt exist!': 'Nie znaleziono takiego użytkownika!',

    T_YES: 'Tak',
    T_NO: 'Nie',
    T_EDIT: 'Edycja',
    T_DELETE: 'Usuwanie',
    T_SORT_ASC_DESC: 'Sortowanie rosnąco/malejąco',
    T_SHOW_ON_PAGE: 'Pokaż na stronie',
    T_SEARCH: 'Szukaj...',
    T_FIRSTNAME: 'Imię',
    T_SURNAME: 'Nazwisko',
    T_DETAILS_ADDRESS: 'Adres',
    T_LOG_OUT: 'Wyloguj',
    T_LOG_IN: 'Zaloguj',
    T_NOT_ACTIVE: 'Nieaktywny',

    T_SAVED: 'Zapisano poprawnie',
    T_ALERT_DELETE_TITLE: 'Usuwanie',
    T_ALERT_DELETE_TEXT: 'Czy na pewno chcesz usunać wybrany rekord ?',
    T_RECORD_DELETED: 'Rekord usunięty',
    T_RECORD_DELETE_ERROR: 'Nie usunięto rekordu',
    T_RECORD_UPDATED: 'Rekord zaktualizowany',
    T_RECORD_ADDED: 'Rekord dodany',
    T_INCORRECT_FORM: 'Niepoprawny formularz',
    T_PASSWORD_CHANGED: 'Hasło zostało zmienione',
    T_PASSWORD_SEND: 'Hasło zostało wysłane',

    T_RESET_PASSWORD: 'Zresetuj hasło',
    T_ALERT_RESET_TITLE: 'Resetowanie hasła',
    T_ALERT_RESET_TEXT:
      'Na adres email użytkownika zostanie wysłany link do zmiany hasła. Czy napewno chcesz zresetować hasło użytkownikowi?',

    T_LOGIN_LABEL: 'Login',
    T_LOGIN_HINT: 'Wpisz conajmniej 6 liter',
    T_LOGIN_INCORRECT_REGEX: 'Tylko litery i cyfry oraz znaki - _', // from regex symfony constraint [0-9a-zA-Z_-]
    T_PASSWORD_LABEL: 'Hasło',
    T_PASSWORD_HINT: 'Wpisz minimum 6 znaków',
    T_PASSWORDREPEAT_LABEL: 'Powtórzenie hasła',
    T_PASSWORDREPEAT_HINT: 'Powtórz poprawnie powyższe hasło',
    T_PASSWORDREPEAT_ERROR: 'Oba hasła muszą się zgadzać',
    T_POSTCODE_LABEL: 'Kod pocztowy',
    T_POSTCODE_HINT: 'np. 33-600',
    T_FLAT_LABEL: 'Numer',
    T_FLAT_HINT: 'Numer domu, np. 5, 23A itp...',
    T_PHONE_LABEL: 'Telefon',
    T_PHONE_HINT: 'np. +48 600 700 800',
    T_COMPANYNAME_LABEL: 'Nazwa firmy',
    T_COMPANYNAME_HINT: 'Wpisz nazwę',
    T_STATUS_USER_LABEL: 'użytkownik aktywny',
    T_STATUS_LABEL: 'aktywny',

    T_RESETPASSWORD_DESC:
      'Wpisz poniżej swój login, link do resetowania hasła zostanie wysłany na maila przypisanego do Twojego konta.',
    T_RESETPASSWORD_TITLE: 'Zmiana hasła',
    T_SELECT_LANGUAGE: 'Wybierz język',
    T_IS_PREMIUM: 'Premium',
    T_PRACTICE_TYPE: 'Typ ćwiczenia',
    T_LABEL_LABEL: 'Opis',
    T_LABEL_HINT: 'Wpisz opis',
    T_ADD_USER_THERAPIST: 'Dodaj terapeute',
    T_ADD_USER_MANAGER: 'Dodaj managera',
    T_VIEW: 'Zobacz',
    T_PHOTO: 'Zdjęcie'
  },
  en: {
    test: '',
  },
}
