import React, { useState } from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import Skeleton from '@material-ui/lab/Skeleton'
import Alert from '@material-ui/lab/Alert'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import AssessmentIcon from '@material-ui/icons/Assessment'
import PhotoIcon from '@material-ui/icons/AddAPhoto'
import EditIcon from '@material-ui/icons/Edit'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import FolderShared from '@material-ui/icons/FolderShared'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { AlertDialog as ChildPhotoRemoveDialog } from 'components/AlertDialog'
import ChildPhotoUploadDialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import moment from 'moment'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { translate } from '_helpers/translate'
import { AlertDialog } from 'components/AlertDialog'
import { useDispatch } from 'react-redux'
import { requestActions } from '_actions'
import { requestConstants } from '_constants'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import * as childSchema from '_schema/child'
import { routePath } from '_constants/routes.constants.js'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    padding: '10px',
  },
  paragraph: {
    margin: '5px',
  },
  paragraphWide: {
    margin: '5px',
    gridColumn: '-1/1',
  },
  align: {
    verticalAlign: 'middle',
  },
  cursor: {
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tableHC: {'& .MuiLink-root': {display: 'flex'},
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  padNone: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  marL: {
    marginLeft: '10px',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  photoPreview: {
    maxWidth: '200px;',
    maxheight: '200px;',
    border: '1px solid',
  },
  icons: {
    display: 'flex',
    justifyContent: 'space-evenly',  marginBottom:-0.5, minHeight: 54, alignItems: 'center',
  }
}))

export default function TableList({
  data,
  isLoading,
  error,
  mainData,
  expandData,
  dataLength,
  pagination,
  queryParams,

  setQueryParams = () => {},
}) {
  const classes = useStyles()
  const [page, setPage] = useState(
    queryParams.page > 0 ? queryParams.page - 1 : 0
  )
  const [rowsPerPage, setRowsPerPage] = useState(queryParams.perPage ?? 12)
  const [open, setOpen] = useState(false)

  const [rowId, setRowId] = useState(null)
  const dispatch = useDispatch()

  const noPhotoSrc =
    process.env.REACT_APP_API_ENTRYPOINT + '/images/no-photo.png'

  const [childPhotoFile, setChildPhotoFile] = useState({})
  const [childPhoto, setChildPhoto] = useState({
    src: noPhotoSrc,
    dialogOpen: false,
    child: {},
    canUpload: false,
    canRemove: false,
    removeDialogOpen: false,
  })


  const handleRefreshList = () => {
    setQueryParams({ ...queryParams })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setQueryParams({ ...queryParams, page: newPage + 1 })
  }

  const handleChangeOrder = (event, newOrder, dir = 'ASC') => {
    event.preventDefault()
    let uqueryParams = queryParams

    if (uqueryParams[`order[${newOrder}]`]) {
      if (uqueryParams[`order[${newOrder}]`] === 'ASC') {
        uqueryParams[`order[${newOrder}]`] = 'DESC'
      } else {
        uqueryParams[`order[${newOrder}]`] = 'ASC'
      }
    } else {
      Object.keys(uqueryParams).map((i) => {
        if (i.indexOf('order') > -1) {
          delete uqueryParams[i]
        }
        return null
      })
      uqueryParams[`order[${newOrder}]`] = dir
    }
    handleRefreshList()
  }

  const handleChangeRowsPerPage = (event) => {
    const perPage = +event.target.value
    setRowsPerPage(perPage)
    setPage(0)
    setQueryParams({
      ...queryParams,
      perPage: perPage,
      page: 1,
    })
  }

  // dialog z usunieciem - otwarcie
  const handleOpenDialogDelete = (event, row) => {
    event.preventDefault()
    setOpen(true)
    setRowId(row)
  }

  // dialog z usunieciem - zamkniecie || akcja == CANCEL
  const handleDialogClose = () => {
    setOpen(false)
    setRowId(null)
  }

  // dialog z usunieciem - akcja == OK
  const handleDialogAgreeDelete = () => {
    const url = `${childSchema.childs.delete.replace('{id}', rowId)}`

    dispatch(requestActions.start(url))

    fetchDataHandleAuthError(
      url,
      'DELETE',
      { body: [] },
      () => {
        dispatch(requestActions.success())
        notification(
          'success',
          translate('T_RECORD_DELETED'),
          translate('T_SAVED')
        )
        setPage(0)
        setQueryParams({ ...queryParams, rowdeleted: rowId, page: 1 })
      },
      (error) => {
        notification(
          'error',
          translate('T_INCORRECT_FORM'),
          translate('T_RECORD_DELETE_ERROR')
        )
      },
      dispatch,
      requestConstants.FAILURE
    )
    handleDialogClose()
  }

  // CHILD PHOTO

  // wyświetlenie dialogu wgrywania foto
  const handleChildPhotoDialogOpen = (event, child) => {
    setChildPhoto({
      ...childPhoto,
      src: child.childPhotoThumbs['full']
        ? process.env.REACT_APP_API_ENTRYPOINT + child.childPhotoThumbs['full']
        : noPhotoSrc,
      dialogOpen: true,
      child: child,
      canRemove: child.childPhotoThumbs['full'] !== undefined,
      canUpload: false,
    })
  }

  // wgranie zdjęcia na serwer
  const handleChildPhotoUploadSubmit = (event) => {
    const url = `${childSchema.childs.photoUpload.replace(
      '{childId}',
      childPhoto.child.id
    )}`
    var formData = new FormData()

    formData.append('childPhotoFile', childPhotoFile)

    fetchDataHandleAuthError(
      url,
      'POST',
      { body: formData },
      () => {
        handleRefreshList()
        notification(
          'success',
          translate('T_SAVING_TITLE'),
          translate('T_SAVING')
        )
        setChildPhoto({ ...childPhoto, canUpload: false, canRemove: true })
      },
      (error) => {
        handleRefreshList()
        notification(
          'error',
          translate('T_SAVING_ERROR_TITLE'),
          translate('T_SAVING_ERROR')
        )
      },
      dispatch,
      requestConstants.FAILURE,
      { 'Content-Type': 'multipart/form-data' }
    )
  }
  // handler zmiany pola childPhotoFile
  const handleChildPhotoFileChange = (event) => {
    const files = Array.from(event.target.files)

    setChildPhotoFile(files[0])

    var reader = new FileReader()
    reader.readAsDataURL(files[0])
    reader.onload = function (e) {
      setChildPhoto({
        ...childPhoto,
        src: e.target.result,
        canUpload: files[0].name && files[0].name.length > 0,
        canRemove: files[0].name && files[0].name.length > 0,
      })
    }
  }

  // zamkniecie okienka wgrywania foto na serwer
  const handleChildPhotoDialogClose = (event) => {
    setChildPhoto({ ...childPhoto, dialogOpen: false })
  }

  // pokazanie dialogu z usunięciem foto
  const handleChildPhotoRemoveDialogAlert = (event) => {
    setChildPhoto({ ...childPhoto, removeDialogOpen: true })
  }

  // Zamknięcie dialogu z potwierdzeniem usunięcia foto
  const handleChildPhotoRemoveDialogClose = (event) => {
    setChildPhoto({ ...childPhoto, removeDialogOpen: false })
  }

  // dialog usuwania foto
  const handleChildPhotoRemoveDialogConfirm = (event) => {
    if (childPhoto.child.childPhotoThumbs['full'] !== undefined) {
      const url = `${childSchema.childs.photoDelete.replace(
        '{childId}',
        childPhoto.child.id
      )}`

      fetchDataHandleAuthError(
        url,
        'POST',
        { body: {} },
        () => {
          handleRefreshList()
          setChildPhoto({
            ...childPhoto,
            src: noPhotoSrc,
            removeDialogOpen: false,
            canRemove: false,
            canUpload: false,
          })
          notification(
            'success',
            translate('T_SAVING_TITLE'),
            translate('T_SAVING')
          )
        },
        (error) => {
          setChildPhoto({ ...childPhoto, removeDialogOpen: false })
          notification(
            'error',
            translate('T_SAVING_ERROR_TITLE'),
            translate('T_SAVING_ERROR')
          )
        },
        dispatch,
        requestConstants.FAILURE
      )
    } else {
      setChildPhoto({
        ...childPhoto,
        src: noPhotoSrc,
        removeDialogOpen: false,
        canUpload: false,
        canRemove: false,
      })
    }
  }

  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            {expandData && (
              <TableCell key={'thc-id'}>
                <div className={classes.tableHC}>
                  <span>ID</span>
                </div>
              </TableCell>
            )}
            {mainData
              .slice(0, mainData[mainData.length - 1].name)
              .map((x, i) => {
                const xkey = x.key.substring(1)
                return (
                  <TableCell key={`thc-${i}`}>
                    <div className={classes.tableHC}>
                      <Tooltip title={translate('T_SORT_ASC_DESC')}>
                        <Link
                          href="#"
                          onClick={(e) => {
                            handleChangeOrder(e, xkey)
                          }}
                          rel="noreferrer"
                          color="inherit"
                        >
                          <span style={{ verticalAlign: 'middle' }}>
                            {x.name}
                          </span>
                          {queryParams[`order[${xkey}]`] &&
                            queryParams[`order[${xkey}]`] === 'DESC' && (
                              <ArrowDropDownIcon
                                style={{ verticalAlign: 'middle' }}
                              />
                            )}
                          {queryParams[`order[${xkey}]`] &&
                            queryParams[`order[${xkey}]`] === 'ASC' && (
                              <ArrowDropUpIcon
                                style={{ verticalAlign: 'middle' }}
                              />
                            )}
                        </Link>
                      </Tooltip>
                    </div>
                  </TableCell>
                )
              })}
            <TableCell key={'thc-edit'}>
              <div className={classes.tableHC}>
                <span></span>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading &&
            [...Array(rowsPerPage)].map((i, key) => (
              <TableRow key={key}>
                <TableCell colSpan={9} key={key}>
                  <Skeleton animation="wave" />
                </TableCell>
              </TableRow>
            ))}

          {error && (
            <TableRow>
              <TableCell colSpan={9} style={{ padding: 0 }}>
                <Alert severity="error">
                  some error occurred, while fetching api
                </Alert>
              </TableCell>
            </TableRow>
          )}

          {!isLoading &&
            data &&
            data.map((row, index) => {
              return (
                <React.Fragment key={`${index}_frag`}>
                  <TableRow key={`${index}_row`}>
                    <TableCell key={`${index}_0`}>{row.firstName}</TableCell>

                    <TableCell key={`${index}_1`}>{row.lastName}</TableCell>

                    <TableCell key={`${index}_2`}>
                      {row.gender === 'M' && translate('T_GENDER_BOY')}
                      {row.gender === 'F' && translate('T_GENDER_GIRL')}
                    </TableCell>

                    <TableCell key={`${index}_3`}>
                      {row.Parent.firstName} {row.Parent.lastName}
                    </TableCell>

                    <TableCell key={`${index}_4`}>
                      {moment(row.birthDate).format('YYYY-MM-DD')}
                    </TableCell>

                    <TableCell key={`${index}_5`} className={classes.icons}>
                      <Tooltip title={translate('T_EDIT')}>
                        <Link
                          component={RouterLink}
                          to={routePath.childs.edit(row.id)}
                          rel="noreferrer"
                        >
                          <EditIcon />
                        </Link>
                      </Tooltip>

                      <Tooltip title={translate('T_CHILD_THERAPIES_LIST')}>
                        <Link
                          component={RouterLink}
                          to={routePath.childTherapies.list(row.id)}
                          rel="noreferrer"
                        >
                          <FolderShared />
                        </Link>
                      </Tooltip>

                      <Tooltip title={translate('T_PHOTO')}>
                        <Link
                          href="#"
                          onClick={(event) => {
                            handleChildPhotoDialogOpen(event, row)
                          }}
                          rel="noreferrer"
                        >
                          <PhotoIcon
                            color={
                              row.childPhotoThumbs.length === 0
                                ? 'secondary'
                                : 'primary'
                            }
                          />
                        </Link>
                      </Tooltip>

                      <Tooltip title={translate('T_DELETE')}>
                        <Link
                          href="#"
                          onClick={(event) => {
                            handleOpenDialogDelete(event, row.id)
                          }}
                          rel="noreferrer"
                        >
                          <DeleteForeverIcon />
                        </Link>
                      </Tooltip>

                      {row.Inquiries.length > 0 && (
                        <Tooltip title={translate('T_CHILD_INQUIRIES_LIST')}>
                          <Link
                            component={RouterLink}
                            to={routePath.childInquiries.list(row.id)}
                            rel="noreferrer"
                          >
                            <AssessmentIcon />
                          </Link>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })}
        </TableBody>
      </Table>

      {pagination && (
        <TablePagination
          rowsPerPageOptions={[1, 2, 12]}
          labelRowsPerPage={translate('T_SHOW_ON_PAGE')}
          nextIconButtonText={''}
          backIconButtonText={''}
          component="div"
          count={dataLength !== undefined ? dataLength : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      <ChildPhotoUploadDialog
        maxWidth={'sm'}
        open={childPhoto.dialogOpen}
        onClose={handleChildPhotoDialogClose}
        aria-labelledby="form-dialog-title"
        className={classes.photoDialog}
      >
        <DialogTitle id="form-dialog-title">
          {translate('T_CHILD_PHOTO_UPLOAD_DIALOG_TITLE')}
        </DialogTitle>
        <Divider component="div" />
        <DialogContent>
          <DialogContentText>
            {translate('T_CHILD_PHOTO_PLEASE_SELECT_FILE')}
          </DialogContentText>
          <input
            type="file"
            accept="image/*"
            onChange={handleChildPhotoFileChange}
          />
          <br></br>&nbsp;<br></br>&nbsp;
          <img src={childPhoto.src} className={classes.photoPreview} alt="" />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleChildPhotoRemoveDialogAlert}
            color="primary"
            disabled={!childPhoto.canRemove}
          >
            {translate('T_REMOVE_PHOTO')}
          </Button>
          <Button onClick={handleChildPhotoDialogClose} color="primary">
            {translate('T_CLOSE')}
          </Button>
          <Button
            onClick={handleChildPhotoUploadSubmit}
            color="primary"
            disabled={!childPhoto.canUpload}
          >
            {translate('T_SUBMIT')}
          </Button>
        </DialogActions>
      </ChildPhotoUploadDialog>

      <ChildPhotoRemoveDialog
        key="remove-childPhoto-dialog"
        open={childPhoto.removeDialogOpen}
        handleClose={handleChildPhotoRemoveDialogClose}
        handleAgree={handleChildPhotoRemoveDialogConfirm}
        title={translate('T_REMOVE_PHOTO_CONFIRM_TITLE')}
        text={translate('T_REMOVE_PHOTO_CONFIRM_TEXT')}
      />

      <AlertDialog
        key="del-dlg"
        open={open}
        handleClose={handleDialogClose}
        handleAgree={() => {
          handleDialogAgreeDelete()
        }}
        title={translate('T_ALERT_DELETE_TITLE')}
        text={translate('T_ALERT_DELETE_TEXT')}
      />
    </React.Fragment>
  )
}
