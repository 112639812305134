import { amber, blue } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'
import { plPL, enUS } from '@material-ui/core/locale'
import { langService } from '_services'

const dark = {
  palette: {
    type: 'dark',
    primary: {
      main: amber[500],
      contrastText: '#000',
    },
    secondary: {
      main: blue[500],
      contrastText: '#fff',
    },
  },
}

const light = {
  palette: {
    type: 'light',
    primary: {
      main: '#2c3d4f',
      contrastText: '#fff',
    },
    secondary: {
      main: '#1abb9a',
      contrastText: '#000',
    },
  },
}

export const theme = (type) =>
  createMuiTheme(type === 'dark' ? dark : light, langService.current() === 'pl' ? plPL : enUS)
