import React, { useState, useEffect } from 'react'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import { validate } from '_helpers/validate'

export const SelectType = ({
  name,
  value,
  validators,
  label,
  hint,
  disabled,
  setValue,
  setValidator,
  setErrorMessageSetter,
  fullWidth = true,
  options = [],
  addEmpty = false,
  multiple = false,
}) => {
  const [state, setState] = useState({
    value: value || '',
    validators: {},
    errors: {},
    error: { status: false, message: false },
  })

  const handleChange = (e) => {
    const value = e.target.value
    setState((state) => ({ ...state, value }))

    validator(value)
  }

  const validator = (value) => {
    const valid = validate(validators, value)
    setState((state) => ({
      ...state,
      error: { status: !valid.result, message: valid.message },
    }))
    setValue(name, !valid.result ? new Error(valid.message) : value)

    return valid.result
  }

  const setErrorMessage = (message) => {
    setState((state) => ({
      ...state,
      error: { status: true, message },
    }))
  }

  useEffect(() => {
    setValue(name, value)
    setValidator(name, validator)
    setErrorMessageSetter(name, setErrorMessage)
  }, [])

  return (
    <div>
      <FormHelperText component="legend">
        {label + (validators.includes('required') ? ' *' : '')}
      </FormHelperText>
      <Select
        name={name}
        onChange={handleChange}
        multiple={multiple}
        value={state.value}
        error={state.error.status}
        disabled={disabled}
        fullWidth={fullWidth}
      >
        {addEmpty && (
          <MenuItem value={null}>
            <em>None</em>
          </MenuItem>
        )}
        {[...options].map((item) => {
          return (
            <MenuItem value={item.id} key={item.id}>
              {item.title}
            </MenuItem>
          )
        })}
      </Select>
      <FormHelperText>
        {state.error.status ? state.error.message : hint}{' '}
      </FormHelperText>
    </div>
  )
}
