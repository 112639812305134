import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { generateBreadcrumbs } from '_helpers/generateBreadcrumbs'
import { SupervisorAccount, Assessment } from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'
import { translate } from '_helpers/translate'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { AccessDenied } from 'pages/AccessDenied'

import { List } from './List'
import { Addto } from './Addto'
import { Editto } from './Editto'

import { List as QuestionsList } from './Questions/List'
import { Addto as QuestionsAddto } from './Questions/Addto'
import { Editto as QuestionsEditto } from './Questions/Editto'

import { List as AnswersList } from './Answers/List'
import { Addto as AnswersAddto } from './Answers/Addto'
import { Editto as AnswersEditto } from './Answers/Editto'

import { List as VerdictsList } from './Verdicts/List'
import { Addto as VerdictsAddto } from './Verdicts/Addto'
import { Editto as VerdictsEditto } from './Verdicts/Editto'

import { List as PracticesList } from './Practices/List'
import { Addto as PracticesAddto } from './Practices/Addto'
import { Editto as PracticesEditto } from './Practices/Editto'



import { routePath } from '_constants/routes.constants.js'

const routes = () => [
  // INQUIRIES
  {
    title: translate('T_INQUIRIES_LIST'),
    type: Route,
    render: () => <List />,
    path: routePath.inquiries.list(''),
    exact: true,
    icon: Assessment,
  },
  {
    title: translate('T_INQUIRY_EDIT'),
    type: Route,
    component: Editto,
    path: routePath.inquiries.edit(':inquiryId', ''),
    exact: true,
  },
  {
    title: translate('T_INQUIRY_ADD'),
    type: Route,
    component: Addto,
    path: routePath.inquiries.add(''),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_INQUIRIES'),
    type: Redirect,
    from: '/',
    to: routePath.inquiries.list(''),
    exact: true,
  },

  // INQUIRY QUESTIONS
  {
    title: translate('T_INQUIRY_QUESTIONS_LIST'),
    type: Route,
    render: ({ match }) => <QuestionsList match={match} />,
    path: routePath.inquiryQuestions.list(':inquiryId', '/list/questions'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_INQUIRY_QUESTION_EDIT'),
    type: Route,
    component: QuestionsEditto,
    path: routePath.inquiryQuestions.edit(
      ':inquiryId',
      ':id',
      '/list/questions'
    ),
    exact: true,
  },
  {
    title: translate('T_INQUIRY_QUESTION_ADD'),
    type: Route,
    component: QuestionsAddto,
    path: routePath.inquiryQuestions.add(':inquiryId', '/list/questions'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_INQUIRY_QUESTIONS'),
    type: Redirect,
    from: '/',
    to: routePath.inquiryQuestions.list(':inquiryId', '/list/questions'),
    exact: true,
  },

  // INQUIRY ANSWERS
  {
    title: translate('T_INQUIRY_ANSWERS_LIST'),
    type: Route,
    render: ({ match }) => <AnswersList match={match} />,
    path: routePath.inquiryAnswers.list(':questionId', '/list/answers'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_INQUIRY_ANSWER_EDIT'),
    type: Route,
    component: AnswersEditto,
    path: routePath.inquiryAnswers.edit(':questionId', ':id', '/list/answers'),
    exact: true,
  },
  {
    title: translate('T_INQUIRY_ANSWER_ADD'),
    type: Route,
    component: AnswersAddto,
    path: routePath.inquiryAnswers.add(':questionId', '/list/answers'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_INQUIRY_ANSWERS'),
    type: Redirect,
    from: '/',
    to: routePath.inquiryAnswers.list(':questionId', '/list/answers'),
    exact: true,
  },

  // INQUIRY VERDICTS
  {
    title: translate('T_INQUIRY_VERDICTS_LIST'),
    type: Route,
    render: ({ match }) => <VerdictsList match={match} />,
    path: routePath.inquiryVerdicts.list(':inquiryId', '/list/verdicts'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_INQUIRY_VERDICT_EDIT'),
    type: Route,
    component: VerdictsEditto,
    path: routePath.inquiryVerdicts.edit(':inquiryId', ':id', '/list/verdicts'),
    exact: true,
  },
  {
    title: translate('T_INQUIRY_VERDICT_ADD'),
    type: Route,
    component: VerdictsAddto,
    path: routePath.inquiryVerdicts.add(':inquiryId', '/list/verdicts'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_INQUIRY_VERDICTS'),
    type: Redirect,
    from: '/',
    to: routePath.inquiryVerdicts.list(':inquiryId', '/list/verdicts'),
    exact: true,
  },

  // INQUIRY PRACTICES
  {
    title: translate('T_INQUIRY_PRACTICES_LIST'),
    type: Route,
    render: ({ match }) => <PracticesList match={match} />,
    path: routePath.inquiryPractices.list(':inquiryId', '/list/practices'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_INQUIRY_PRACTICES_EDIT'),
    type: Route,
    component: PracticesEditto,
    path: routePath.inquiryPractices.edit(':inquiryId', ':id', '/list/practices'),
    exact: true,
  },
  {
    title: translate('T_INQUIRY_PRACTICES_ADD'),
    type: Route,
    component: PracticesAddto,
    path: routePath.inquiryPractices.add(':inquiryId', '/list/practices'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_INQUIRY_PRACTICES'),
    type: Redirect,
    from: '/',
    to: routePath.inquiryPractices.list(':inquiryId', '/list/practices'),
    exact: true,
  },






  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

const sideMenu = () => [routes()[0]]

export const Inquiries = (props) => {
  const { match, location } = props

  if (hasRole(authConstants.ROLE_SUPER_ADMIN)) {
    return (
      <Page
        menu={prefixPaths(match.url, sideMenu())}
        routes={generateRoutes(
          prefixPaths(match.url, routes(), ['path', 'from', 'to'])
        )}
        breadcrumbs={generateBreadcrumbs(
          location.pathname,
          prefixPaths(match.url, routes(), ['path', 'from'])
        )}
      />
    )
  } else {
    return <AccessDenied />
  }
}
