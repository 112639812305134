import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { requestActions } from '_actions'
import { requestConstants } from '_constants'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { fetchData } from '_helpers/fetchData'
import { notification } from '_helpers/notification'
import { StringType } from 'components/form/StringType'
import { SelectType } from 'components/form/SelectType'
import { CheckboxType } from 'components/form/CheckboxType'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Save } from '@material-ui/icons'
import { dataToObj } from '_helpers/tableProps'
import { getProps } from '_helpers/tableProps'
import { translate } from '_helpers/translate'
import { useStyles } from 'components/form/FormStyles'
import { useHistory } from 'react-router-dom'
import * as schema from '_schema/healthCenter'
import * as provinceSchema from '_schema/province'
import { routePath } from '_constants/routes.constants.js'

export const Addto = ({ match, dataResource }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    params: { id },
  } = match

  const url = id ? schema.healthCenter.set : schema.healthCenter.add
  const method = id ? 'PUT' : 'POST'
  const data = id ? dataResource : null

  const [state, setState] = useState({
    values: {},
    errors: {},
    validators: {},
    errorMessageSetters: {},
    invalid: 0,
    isProcessing: false,
    url: url.includes('{id}') ? url.replace('{id}', id) : url,
    provinces: [],
  })

  useEffect(() => {
    fetchData(
      provinceSchema.province.list,
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      setState((state) => ({ ...state, provinces: response.content }))
    })
  }, []) // bez parametrów odpala sie tylko raz

  const fields = {
    name: {
      type: 'string',
      validate: ['required'],
      label: translate('T_NAME_LABEL'),
      hint: translate('T_NAME_HINT'),
    },
    address: {
      type: 'string',
      validate: ['required'],
      label: translate('T_ADDRESS_LABEL'),
      hint: translate('T_ADDRESS_HINT'),
    },
    postcode: {
      type: 'string',
      validate: ['required'],
      label: translate('T_POSTCODE_LABEL'),
      hint: translate('T_POSTCODE_HINT'),
    },
    city: {
      type: 'string',
      validate: ['required'],
      label: translate('T_CITY_LABEL'),
      hint: translate('T_CITY_HINT'),
    },
    Province: {
      type: 'select',
      validate: ['required'],
      options: state.provinces.map((item) => {
        return { id: '/province/' + item.id, title: item.title }
      }),
      label: translate('T_PROVINCE_LABEL'),
      hint: translate('T_PROVINCE_HINT'),
    },
    phone: {
      type: 'string',
      validate: ['required'],
      label: translate('T_PHONE_LABEL'),
      hint: translate('T_PHONE_HINT'),
    },
    email: {
      type: 'string',
      validate: ['required'],
      label: translate('T_EMAIL_LABEL'),
      hint: translate('T_EMAIL_HINT'),
    },
    www: {
      type: 'string',
      validate: ['required'],
      label: translate('T_WWW_LABEL'),
      hint: translate('T_WWW_HINT'),
    },
    stat: {
      type: 'check',
      validate: [],
      label: translate('T_STATUS_LABEL'),
    },
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (
      Object.keys(state.validators).length &&
      !Object.keys(state.validators).reduce(
        (status, name) =>
          state.validators[name].validator(
            ...Object.keys(state.values)
              .filter((item) => state.validators[name].values.includes(item))
              .map((item) => state.values[item])
          ) && status,
        true
      )
    ) {
      return
    }

    setState((state) => ({ ...state, isProcessing: true }))
    dispatch(requestActions.start(state.url))

    fetchDataHandleAuthError(
      state.url,
      method,
      { body: JSON.stringify(dataToObj(state.values)) },
      () => {
        setState((state) => ({ ...state, isProcessing: false }))
        dispatch(requestActions.success())
        notification(
          'success',
          method === 'PUT'
            ? translate('T_RECORD_UPDATED')
            : translate('T_RECORD_ADDED'),
          translate('T_SAVED')
        )
        handleSuccess && handleSuccess(state.values)
      },
      (error) => {
        setState((state) => ({ ...state, isProcessing: false }))
        error.response.violations.map((item) =>
          state.errorMessageSetters[item.propertyPath](translate(item.message))
        )
        notification(
          'error',
          error.response.violations.length
            ? translate('T_INCORRECT_FORM')
            : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      requestConstants.FAILURE
    )
  }

  const handleSuccess = (val) => {
    //check if admin change own profile

    history.push(routePath.healthCenter.list())
  }

  const setValue = (name, value) => {
    const isInvalid = value instanceof Error
    setState((state) => ({
      ...state,
      values: { ...state.values, [name]: !isInvalid && value },
      errors: { ...state.errors, [name]: isInvalid },
      invalid:
        state.invalid +
        (!!state.errors[name] === !isInvalid && -1 + isInvalid * 2),
    }))
  }

  const setValidator = (name, validator, values) => {
    setState((state) => ({
      ...state,
      validators: {
        ...state.validators,
        [name]: { validator, values: values || [name] },
      },
    }))
  }

  const setErrorMessageSetter = (name, errorMessageSetter) => {
    setState((state) => ({
      ...state,
      errorMessageSetters: {
        ...state.errorMessageSetters,
        [name]: errorMessageSetter,
      },
    }))
  }

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <form onSubmit={handleSubmit} className={classes.root}>
          <Grid container spacing={3}>
            {Object.keys(fields).map((name, key) => {
              if (!fields[name]) return null

              return (
                <Grid item xs={4} key={`${key}_grid`}>
                  {fields[name].type === 'string' && (
                    <StringType
                      key={key}
                      name={fields[name].name ? fields[name].name : name}
                      label={fields[name].label}
                      type={fields[name].type}
                      hint={fields[name].hint}
                      value={
                        data &&
                        (fields[name]['resource']
                          ? getProps(data, fields[name]['resource'])
                          : data[name])
                      }
                      disabled={
                        fields[name].disabled === true
                          ? true
                          : state.isProcessing
                      }
                      validators={fields[name].validate || []}
                      setValue={setValue}
                      setValidator={setValidator}
                      setErrorMessageSetter={setErrorMessageSetter}
                    />
                  )}

                  {fields[name].type === 'check' && (
                    <CheckboxType
                      key={key}
                      name={fields[name].name ? fields[name].name : name}
                      label={fields[name].label}
                      hint={fields[name].hint}
                      checked={
                        data &&
                        (fields[name]['resource']
                          ? getProps(data, fields[name]['resource'])
                          : data[name]
                          ? data[name]
                          : false)
                      }
                      disabled={
                        fields[name].disabled === true
                          ? true
                          : state.isProcessing
                      }
                      validators={fields[name].validate || []}
                      setValue={setValue}
                      setValidator={setValidator}
                      setErrorMessageSetter={setErrorMessageSetter}
                    />
                  )}
                  {fields[name].type === 'select' && (
                    <SelectType
                      value={
                        data &&
                        (fields[name]['resource']
                          ? getProps(data, fields[name]['resource'])
                          : data[name])
                      }
                      options={fields[name].options}
                      name={name}
                      validators={fields[name].validate || []}
                      label={fields[name].label}
                      hint={fields[name].hint}
                      setValue={setValue}
                      setValidator={setValidator}
                      setErrorMessageSetter={setErrorMessageSetter}
                    />
                  )}
                </Grid>
              )
            })}

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<Save />}
                className={classes.save}
                disabled={state.isProcessing || !!state.invalid}
              >
                {id ? translate('T_SAVE') : translate('T_ADD_SAVE')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </React.Fragment>
  )
}
