import React from 'react'
import { useSelector } from 'react-redux'
import {
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useLocalCss = makeStyles((theme) => ({
  fieldsBox: {
    width: '300px',
  },
  field: {
    width: '75px',
    height: '75px',
    margin: '1px',
    border: '1px solid #888',
    backgroundColor: '#fff',
    textAlign: 'center',
    display: 'table-cell',
    verticalAlign: 'middle',
    padding: '5px',
  },
  img: {
    width: '55px',
    height: '55px',
  },
}))

export const PracticeStagePreview = ({
  gameIdname,
  fieldsNames,
  fieldsValues,
}) => {
  const css = useLocalCss()


  const availableElements = useSelector((state) => {
    return state.game.elementsById
  })

  const fieldPreview = (fieldName) => {
    const elementIri = fieldsValues['Element' + fieldName] ? fieldsValues['Element' + fieldName]: null
    const element = availableElements[elementIri] ? availableElements[elementIri] : null

    if (!fieldsNames.includes(fieldName)) {
      return (
        <React.Fragment key={'key_' + fieldName + '_preview'}>
          <div className={css.field}></div>
        </React.Fragment>
      )
    }

    if (!element) {
      return (
        <React.Fragment key={'key_' + fieldName + '_preview'}>
          <div className={css.field}>{fieldName}</div>
        </React.Fragment>
      )
    }

    if (element && element.type === 'TYPE_PHOTO') {
      return (
        <React.Fragment key={'key_' + fieldName + '_preview'}>
          <div className={css.field}>
            <img className={css.img} src={ process.env.REACT_APP_API_ENTRYPOINT + element.photoThumbs['128x128'] } alt="" />
          </div>
        </React.Fragment>
      )
    }

    if (element && element.type === 'TYPE_AUDIO') {
      return (
        <React.Fragment key={'key_' + fieldName + '_preview'}>
          <div className={css.field}>audio</div>
        </React.Fragment>
      )
    }

    if (element && element.type === 'TYPE_VIDEO') {
      return (
        <React.Fragment key={'key_' + fieldName + '_preview'}>
          <div className={css.field}>video</div>
        </React.Fragment>
      )
    }
  }

  return (
    <React.Fragment>
      <div className={css.fieldsBox}>
        <Grid container spacing={0}>
          <Grid item xs={3}>
            {fieldPreview('A1')}
          </Grid>
          <Grid item xs={3}>
            <React.Fragment>
              <div className={css.field}></div>
            </React.Fragment>
          </Grid>
          <Grid item xs={3}>
            <React.Fragment>
              <div className={css.field}></div>
            </React.Fragment>
          </Grid>
          <Grid item xs={3}>
            <React.Fragment>
              <div className={css.field}></div>
            </React.Fragment>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={3}>
            {fieldPreview('B1')}
          </Grid>
          <Grid item xs={3}>
            {fieldPreview('B2')}
          </Grid>
          <Grid item xs={3}>
            {fieldPreview('B3')}
          </Grid>
          <Grid item xs={3}>
            {fieldPreview('B4')}
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={3}>
            {fieldPreview('C1')}
          </Grid>
          <Grid item xs={3}>
            {fieldPreview('C2')}
          </Grid>
          <Grid item xs={3}>
            {fieldPreview('C3')}
          </Grid>
          <Grid item xs={3}>
            {fieldPreview('C4')}
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={3}>
            {fieldPreview('D1')}
          </Grid>
          <Grid item xs={3}>
            {fieldPreview('D2')}
          </Grid>
          <Grid item xs={3}>
            {fieldPreview('D3')}
          </Grid>
          <Grid item xs={3}>
            {fieldPreview('D4')}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}
