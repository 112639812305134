import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useHistory } from 'react-router-dom'
import { translate } from '_helpers/translate'
import { notification } from '_helpers/notification'
import { Copyright } from 'components/Copyright'
import * as schema from '_schema/user'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  spinner: {
    height: '24px',
    animationName: '$spin',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  formError: {
    display: 'block',
    color: 'red',
  },
}))

export const ForgotPassword = () => {
  const classes = useStyles()

  const initialState = {
    password: '',
    isSubmitting: false,
    errorMessage: null,
    errorRepeat: false,
  }

  const [data, setData] = React.useState(initialState)
  const history = useHistory()

  const handleInputChange = (event) => {
    setData({
      ...data,
      errorMessage: null,
      [event.target.name]: event.target.value,
    })
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()

    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    })
    fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}${schema.security.startreset}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login: data.login,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          history.push('/')
          notification(
            'success',
            translate('T_SAVED'),
            translate('T_PASSWORD_SEND')
          )
        } else if (response.status === 400 || response.status === 404) {
          return response.json()
        } else throw response
      })
      .then((result) => {
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: result.violations
            ? translate(result.violations[0].message)
            : result.detail
            ? translate(result.detail)
            : 'Error',
        })
      })
      .catch((error) => {
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: error.message || error.statusText,
        })
      })
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {translate('T_RESETPASSWORD_TITLE')}
        </Typography>
        <Typography component="p" align="center">
          <br />
          {translate('T_RESETPASSWORD_DESC')}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="login"
                label={translate('T_LOGIN_LABEL')}
                type="text"
                id="login"
                autoComplete="current-password"
                value={data.login}
                onChange={handleInputChange}
                helperText={data.errorMessage ?? ''}
                error={data.errorMessage ? true : false}
              />

            </Grid>
          </Grid>
          <Button
            disabled={data.isSubmitting}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {data.isSubmitting ? (
              <img
                className={classes.spinner}
                src="/images/logo.svg"
                alt="loading icon"
              />
            ) : (
              translate('T_LOGIN_RESET')
            )}
          </Button>
        </form>
      </div>

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
