import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { generateBreadcrumbs } from '_helpers/generateBreadcrumbs'
import { SupervisorAccount } from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'
import { translate } from '_helpers/translate'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { AccessDenied } from 'pages/AccessDenied'

import { List } from './List'
import { Addto } from './Addto'
import { Editto } from './Editto'

import { List as TherapiesList } from './TherapistTherapies/List'
import { Addto as TherapiesAddto } from './TherapistTherapies/Addto'
import { Editto as TherapiesEditto } from './TherapistTherapies/Editto'

import { List as ParentChildsList } from './ParentChilds/List'

import { routePath } from '_constants/routes.constants.js'

const routes = () => [
  {
    title: translate('T_USERS_LIST'),
    type: Route,
    render: () => <List />,
    path: routePath.users.list(''),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_USER_EDIT'),
    type: Route,
    component: Editto,
    path: routePath.users.edit(':userId', ''),
    exact: true,
  },
  {
    title: translate('T_ADD_USER'),
    type: Route,
    component: Addto,
    path: routePath.users.add('', ':role'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_USERS'),
    type: Redirect,
    from: '/',
    to: routePath.users.list(''),
    exact: true,
  },

  {
    // THERAPIST THERAPIES
    title: translate('T_THERAPIST_THERAPIES_LIST'),
    type: Route,
    render: ({ match }) => <TherapiesList match={match} />,
    path: routePath.therapistTherapies.list(':therapistId', '/list/therapies'),
    exact: true,
  },
  {
    title: translate('T_THERAPIST_THERAPY_EDIT'),
    type: Route,
    component: TherapiesEditto,
    path: routePath.therapistTherapies.edit(
      ':therapistId',
      ':id',
      '/list/therapies'
    ),
    exact: true,
  },
  {
    title: translate('T_THERAPIST_THERAPY_ADD'),
    type: Route,
    component: TherapiesAddto,
    path: routePath.therapistTherapies.add(':therapistId', '/list/therapies'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_THERAPIST_THERAPIES'),
    type: Redirect,
    from: '/',
    to: routePath.therapistTherapies.list(':therapistId', '/list/therapies'),
    exact: true,
  },

  {
    // PARENT CHILDS
    title: translate('T_PARENT_CHILDS_LIST'),
    type: Route,
    render: ({ match }) => <ParentChildsList match={match} />,
    path: routePath.parentChilds.list(':parentId', '/list/parentChilds'),
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

const menu = () => [routes()[0]]

export const Users = (props) => {
  const { match, location } = props

  if (hasRole(authConstants.ROLE_SUPER_ADMIN)) {
    return (
      <Page
        menu={prefixPaths(match.url, menu())}
        routes={generateRoutes(
          prefixPaths(match.url, routes(), ['path', 'from', 'to'])
        )}
        breadcrumbs={generateBreadcrumbs(
          location.pathname,
          prefixPaths(match.url, routes(), ['path', 'from'])
        )}
      />
    )
  } else {
    return <AccessDenied />
  }
}
