import React from 'react'
import clsx from 'clsx'
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  List,
} from '@material-ui/core'
import { Menu, ExitToApp } from '@material-ui/icons'
import { TopMenu } from 'components/menu/TopMenu'
import { UserMenu } from 'components/menu/UserMenu'
import { LangMenu } from 'components/menu/LangMenu'
import { useStyles } from './AppBarStyles'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import { translate } from '_helpers/translate'
export const AppBar = ({ status, handlers }) => {
  const classes = useStyles()
  return (
    <MuiAppBar
      position="absolute"
      className={clsx(classes.appBar, status.isExpanded || classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        {status.withMenuIcon && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handlers.handleExpand}
            className={clsx(
              classes.menuButton,
              status.isExpanded || classes.menuButtonHidden
            )}
          >
            <Menu />
          </IconButton>
        )}
        <List className={classes.topMenu}>
          <TopMenu />
        </List>

        <UserMenu />
        <LangMenu />

        <Tooltip title={translate('T_LOG_OUT')}>
          <IconButton color="inherit" onClick={handlers.handleLogout}>
            <ExitToApp />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </MuiAppBar>
  )
}

AppBar.propTypes = {
  handlers: PropTypes.shape({
    handleExpand: PropTypes.func,
    handleLogout: PropTypes.func.isRequired,
  }).isRequired,
  status: PropTypes.shape({
    isExpanded: PropTypes.bool,
    withMenuIcon: PropTypes.bool,
  }).isRequired,
}
