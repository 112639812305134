import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  seeMore: {
    paddingTop: theme.spacing(3),
    marginTop: 'auto',
  },
}))

export default function ConfigCokolwiek() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.seeMore}>
        <Button variant="outlined" color="primary">
          Ognia!
        </Button>
      </div>
    </React.Fragment>
  )
}
