import React, { useState, useEffect } from 'react'

import { fetchData } from '_helpers/fetchData'

import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import TableList from './TableList'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { makeStyles } from '@material-ui/core/styles'

import { setProps } from '_helpers/tableProps'
import { translate } from '_helpers/translate'

import { healthCenter } from '_schema/healthCenter'
import * as provinceSchema from '_schema/province'

import { routePath } from '_constants/routes.constants.js'

const useStyles = makeStyles({
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-18px',
    marginBottom: '20px',
  },
  provinceSelect: {
    minWidth: '180px',
    marginRight: '10px',
  },
})

const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}${healthCenter.list}`
const typingTimeout = 1000

const listCols = () => {
  return {
    name: translate('T_NAME'),
    city: translate('T_CITY'),
    'Province.title': translate('T_PROVINCE'),
    phone: translate('T_PHONE'),
    stat: translate('T_STAT'),
    displayCollumn: 5, //extra, tell how many kolumns show
  }
}

const mainData = () => {
  return setProps(listCols())
}

export const List = () => {
  const listqueryParams =
    localStorage.listHealthCenterQueryParams === undefined
      ? {
          search: '',
          province_id: '',

          page: 1,
          perPage: 12,
          'order[name]': 'ASC',
        }
      : JSON.parse(localStorage.listHealthCenterQueryParams)

  const classes = useStyles()
  const auth = useSelector((state) => state.auth)
  const [queryParams, setQueryParams] = useState(listqueryParams)
  const [typingState, setTypingState] = useState({ timeoutId: null })
  const [searchText, setSearchText] = useState(listqueryParams.search ?? '')
  const [provinceId, setProvinceId] = useState(
    listqueryParams.province_id ?? ''
  )
  const [provinces, setProvinces] = useState([])

  useEffect(() => {
    fetchData(
      provinceSchema.province.list,
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      setProvinces(response.content)
    })
  }, []) // bez parametrów odpala sie tylko raz

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)

  useEffect(() => {
    localStorage.listHealthCenterQueryParams = JSON.stringify(queryParams)
    return () => {
      // cleanup
    }
  }, [queryParams])

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  const handleProvinceChange = (e) => {
    const provinceId = e.target.value
    setProvinceId(provinceId)
    setQueryParams({
      ...queryParams,
      province_id: provinceId,
      page: 1,
    })
  }

  return (
    <React.Fragment>
      <div className={classes.gridParent}>
        <FormControl className={classes.provinceSelect}>
          <InputLabel>{translate('T_PROVINCE')}</InputLabel>
          <Select value={provinceId} onChange={handleProvinceChange}>
            <MenuItem value="">{translate('T_ALL')}</MenuItem>
            {[...provinces].map((item) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <TextField
          label={translate('T_SEARCH')}
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <TableList
        data={res.state.data ? res.state.data?.content : []}
        isLoading={res.state.isLoading}
        error={res.state.error}
        mainData={mainData()}
        expandData={false}
        dataLength={res.state.data?.totalItems}
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
      <div>
        <Button
          component={RouterLink}
          to={routePath.healthCenter.add()}
          variant="contained"
          color="primary"
        >
          {translate('T_HEALTH_CENTER_ADD')}
        </Button>
      </div>
    </React.Fragment>
  )
}
