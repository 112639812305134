import React from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Home, SupervisorAccount, AssignmentInd, VideogameAsset, ChildCare, Assessment } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

import { translate } from '_helpers/translate'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'
import { routePath } from '_constants/routes.constants.js'

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.contrastText,
    },
  },
  active: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '& .MuiListItemIcon-root': {
      color: theme.palette.secondary.contrastText,
    },
  },
  icon: {
    minWidth: '40px',
  },
}))


export const TopMenu = () => {
  const classes = useStyles()
  const theme = useTheme()
  const is = useMediaQuery(theme.breakpoints.down('lg'))
  let items = [{ path: '/home', icon: Home, title: !is ? 'Home' : '' }]

  if (hasRole(authConstants.ROLE_SUPER_ADMIN)) {
    items.push({
      path: routePath.superadmins.main,
      icon: SupervisorAccount,
      title: translate('T_ADMINISTRATION'),
    })
  }

  if (hasRole(authConstants.ROLE_SUPER_ADMIN)) {
    items.push({
      path: routePath.users.main,
      icon: AssignmentInd,
      title: translate('T_USERS'),
    })
  }

  if (hasRole(authConstants.ROLE_SUPER_ADMIN)) {
    items.push({
      path: routePath.childs.main,
      icon: ChildCare,
      title: translate('T_CHILDS'),
    })
  }

  if (hasRole(authConstants.ROLE_SUPER_ADMIN)) {
    items.push({
      path: routePath.healthCenter.main,
      icon: AssignmentInd,
      title: translate('T_HEALTH_CENTERS'),
    })
  }

  if (hasRole(authConstants.ROLE_SUPER_ADMIN)) {
    items.push({
      path: routePath.profession.main,
      icon: AssignmentInd,
      title: translate('T_PROFESSIONS'),
    })
  }

  if (hasRole(authConstants.ROLE_SUPER_ADMIN)) {
    items.push({
      path: routePath.inquiries.main,
      icon: Assessment,
      title: translate('T_INQUIRIES'),
    })
  }

  if (hasRole(authConstants.ROLE_SUPER_ADMIN)) {
    items.push({
      path: routePath.games.main,
      icon: VideogameAsset,
      title: translate('T_GAMES'),
    })
  }
  
  if (hasRole(authConstants.ROLE_SUPER_ADMIN)) {
    items.push({
      path: routePath.config.main,
      icon: Assessment,
      title: translate('T_CONFIG'), 
    })
  }
  

  return (
    <>
      {items.map((item, i) => {
        const IconComponent = item.icon
        return (
          <NavLink
            key={`tm-${i}`}
            to={item.path}
            className={classes.link}
            activeClassName={classes.active}
            exact={!!item.exact}
          >
            <ListItem button>
              <ListItemIcon className={classes.icon}>
                <IconComponent />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          </NavLink>
        )
      })}
    </>
  )
}
