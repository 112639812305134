import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { requestActions } from '_actions'
import { requestConstants } from '_constants'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Link } from '@material-ui/core'
import { StatSwitch } from 'components/tableList/StatSwitch'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import {
  Save,
  DragIndicator,
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
} from '@material-ui/icons'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { fetchData } from '_helpers/fetchData'
import { notification } from '_helpers/notification'
import { dataToObj } from '_helpers/tableProps'
import { translate } from '_helpers/translate'
import { AlertDialog as RemoveConfirmDialog } from 'components/AlertDialog'
import { practiceStage as practiceStageSchema } from '_schema/practiceStage'
import { practice as practiceSchema } from '_schema/practice'
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
  arrayMove,
} from 'react-sortable-hoc'
import { PracticeStageForm } from './PracticeStageForm'

export const PracticeStagesTable = ({ practice }) => {
  const dispatch = useDispatch()

  const useStyles = makeStyles({
    rowHead: {
      '& th': {
        textTransform: 'uppercase',
      },
    },
    dragHelper: {
      border: '1px solid silver',
      borderRadius: '7px',
      backgroundColor: '#D2DAFF',
      '& cellXS': {
        width: '50px',
        minWidth: '50px',
      },
      '& cellS': {
        width: '100px',
        minWidth: '100px',
        '& a': {
          marginLeft: 10
        },
      },
      '& cellM': {
        width: '250px',
        minWidth: '250px',
      },
    },
    cellXS: {
      width: '50px',
      minWidth: '50px',
    },
    cellS: {
      width: '100px',
      minWidth: '100px',
      '& a': {
        marginLeft: 10
      },
    },
    cellM: {
      width: '250px',
      minWidth: '250px',
    },
    cellAuto: {
      width: '100%',
    },
  })

  const classes = useStyles()
  const [saveOrd, setSaveOrd] = useState(0)
  const [open, setOpen] = useState(false)
  const [practiceStageDialogOpen, setPracticeStageDialogOpen] = useState(false)
  const [rowId, setRowId] = useState(null)
  const [practiceStage, setPracticeStage] = useState({})
  const [state, setState] = useState({
    practiceStages: [],
    isProcessing: false,
    errorMessageSetters: {},
  })

  useEffect(() => {
    loadElements()
  }, [])

  useEffect(() => {
    if (saveOrd > 0) {
      handleSaveOrd()
    }
  }, [saveOrd])

  const loadElements = () => {
    fetchData(
      practiceStageSchema.listAll.replace('{practiceId}', practice.id),
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      setState({ ...state, practiceStages: response.content })
    })
  }

  const performSort = ({ oldIndex, newIndex }) => {
    setState(({ practiceStages }) => ({
      practiceStages: arrayMove(practiceStages, oldIndex, newIndex),
    }))

    setSaveOrd(saveOrd + 1)
  }

  const handleSaveOrd = () => {
    var ordData = []
    // eslint-disable-next-line array-callback-return
    state.practiceStages.map((element, key) => {
      ordData.push({
        '@id': practiceStageSchema.get.replace('{id}', element.id),
        ord: 1 + key,
      })
    })

    const requestData = JSON.stringify(
      dataToObj({
        PracticeStages: ordData,
      })
    )

    setState((state) => ({ ...state, isProcessing: true }))
    dispatch(requestActions.start(state.url))

    fetchDataHandleAuthError(
      practiceSchema.set.replace('{id}', practice.id),
      'PUT',
      { body: requestData },
      (response) => {
        setState((state) => ({ ...state, isProcessing: false }))
        notification('success',translate('T_RECORD_UPDATED'),translate('T_SAVED'))
      },
      (error) => {
        setState((state) => ({ ...state, isProcessing: false }))
        notification('error', error.response.violations.length ? translate('T_INCORRECT_FORM') : error.response.detail,error.response.title)
      },
      dispatch,
      requestConstants.FAILURE
    )
  }

  const handleCreateElement = () => {
    const requestData = JSON.stringify(
      dataToObj({
        Practice: practiceSchema.get.replace('{id}', practice.id),
        title: '',
        text: '',
      })
    )

    setState((state) => ({ ...state, isProcessing: true }))
    dispatch(requestActions.start(state.url))

    fetchDataHandleAuthError(
      practiceStageSchema.add,
      'POST',
      { body: requestData },
      (response) => {
        loadElements()
        setState((state) => ({ ...state, isProcessing: false }))
        notification(
          'success',
          translate('T_RECORD_CREATED'),
          translate('T_SAVED')
        )
      },
      (error) => {
        setState((state) => ({ ...state, isProcessing: false }))
        notification(
          'error',
          error.response.violations.length
            ? translate('T_INCORRECT_FORM')
            : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      requestConstants.FAILURE
    )
  }

  const handleOpenDialogDelete = (event, row) => {
    event.preventDefault()
    setOpen(true)
    setRowId(row.id)
  }

  const handleDialogClose = () => {
    setOpen(false)
    setRowId(null)
  }

  const handleDialogAgreeDelete = () => {
    const url = practiceStageSchema.delete.replace('{id}', rowId)

    dispatch(requestActions.start(url))

    fetchDataHandleAuthError(
      url,
      'DELETE',
      { body: [] },
      () => {
        dispatch(requestActions.success())
        notification(
          'success',
          translate('T_RECORD_DELETED'),
          translate('T_SAVED')
        )
        loadElements()
      },
      (error) => {
        notification(
          'error',
          translate('T_INCORRECT_FORM'),
          translate('T_RECORD_DELETE_ERROR')
        )
      },
      dispatch,
      requestConstants.FAILURE
    )

    handleDialogClose()
  }

  const handlePracticeStageDialogOpen = (event, element) => {
    event.preventDefault()
    setPracticeStage(element)
    setPracticeStageDialogOpen(true)
  }

  const getPracticeStage = () => {
    return practiceStage
  }

  const handlePracticeStageDialogClose = () => {
    setPracticeStageDialogOpen(false)
  }

  const PracticeSortableList = sortableContainer(({ children }) => (
    <TableBody className={classes.sortableList} spacing={0}>
      {children}
    </TableBody>
  ))

  const DragHandle = sortableHandle(() => {
    return <DragIndicator />
  })

  const PracticeSortableElement = sortableElement(({ value, element }) => {
    return (
      <TableRow>
        <TableCell className={classes.cellXS}>
          <DragHandle className={classes.subElement} />
        </TableCell>
        <TableCell className={classes.cellXS}>{element.id}</TableCell>
        <TableCell className={classes.cellM}>{element.title}</TableCell>
        <TableCell className={classes.cellAuto}>{element.text}</TableCell>
        <TableCell className={classes.cellM}>{element.score}</TableCell>
        <TableCell className={classes.cellM}>
            <StatSwitch
              value={element.stat}
              fieldName="stat"
              apiUrl={practiceStageSchema.set.replace('{id}', element.id)}
            />
        </TableCell>
        <TableCell className={classes.cellS}>
          <Link
            href="#"
            onClick={(event) => {
              handlePracticeStageDialogOpen(event, element)
            }}
            rel="noreferrer"
          >
            <EditIcon />
          </Link>

          <Link
            href="#"
            onClick={(event) => {
              handleOpenDialogDelete(event, element)
            }}
            rel="noreferrer"
          >
            <DeleteForeverIcon />
          </Link>
        </TableCell>
      </TableRow>
    )
  })

  return (
    <React.Fragment>
      <h3>{translate('T_GAME_PRACTICE_STAGES_LIST')}</h3>
      <Table size="small">
        <TableHead>
          <TableRow className={classes.rowHead}>
            <TableCell className={classes.cellXS}></TableCell>
            <TableCell className={classes.cellXS}>
              {translate('T_ID')}
            </TableCell>
            <TableCell className={classes.cellM}>
              {translate('T_TITLE')}
            </TableCell>
            <TableCell className={classes.cellAuto}>
              {translate('T_DESCRIPTION')}
            </TableCell>

            <TableCell className={classes.cellM}>
              {translate('T_SCORE')}
            </TableCell>

            <TableCell className={classes.cellM}>
              {translate('T_STAT')}
            </TableCell>

            <TableCell className={classes.cellS}></TableCell>
          </TableRow>
        </TableHead>

        <PracticeSortableList
          onSortEnd={performSort}
          useDragHandle
          helperClass={classes.dragHelper}
        >
          {state.practiceStages.length === 0 && (
            <TableRow>
              <TableCell colspan="5" >
                Nie dodano etapów
              </TableCell>
            </TableRow>
          )}

          {state.practiceStages.map((element, key) => (
            <PracticeSortableElement
              key={`item-${key}`}
              index={key}
              value={key}
              element={element}
            />
          ))}
        </PracticeSortableList>
      </Table>

      <div>
        <br/>
        <br/>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<Save />}
          className={classes.NIC}
          onClick={handleCreateElement}
        >
          {translate('T_ADD_GAME_PRACTICE_STAGE')}
        </Button>
      </div>

      <RemoveConfirmDialog
        key="del-dlg"
        open={open}
        handleClose={handleDialogClose}
        handleAgree={() => {
          handleDialogAgreeDelete()
        }}
        title={translate('T_ALERT_DELETE_TITLE')}
        text={translate('T_ALERT_DELETE_TEXT')}
      />

      <PracticeStageForm
        key="edit-form-dlg"
        open={practiceStageDialogOpen}
        game={practice.Game}
        reloadPracticeStagesListHandler={loadElements}
        dataResourceHandler={() => getPracticeStage}
        handleClose={handlePracticeStageDialogClose}
      />
    </React.Fragment>
  )
}
