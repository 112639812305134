import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import Skeleton from '@material-ui/lab/Skeleton'
import Alert from '@material-ui/lab/Alert'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'
import moment from 'moment'
import { translate } from '_helpers/translate'
import { AlertDialog } from 'components/AlertDialog'
import { StatSwitch } from 'components/tableList/StatSwitch'
import { requestActions } from '_actions'
import { requestConstants } from '_constants'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { therapies as schema } from '_schema/therapy'
import { routePath } from '_constants/routes.constants.js'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    padding: '9px',
  },
  paragraph: {
    margin: '5px',
  },
  paragraphWide: {
    margin: '5px',
    gridColumn: '-1/1',
  },
  align: {
    verticalAlign: 'middle',
  },
  cursor: {
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tableHC: {'& .MuiLink-root': {display: 'flex'},
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  padNone: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  marL: {
    marginLeft: '10px',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  icons: {
    display: 'flex',
    justifyContent: 'space-evenly',  marginBottom:-0.5, minHeight: 54, alignItems: 'center',
  }
}))

export default function TableList({
  data,
  isLoading,
  error,
  mainData,
  expandData,
  dataLength,
  pagination,
  queryParams,
  setQueryParams = () => {},
}) {
  const classes = useStyles()
  const [page, setPage] = useState(
    queryParams.page > 0 ? queryParams.page - 1 : 0
  )
  const [rowsPerPage, setRowsPerPage] = useState(queryParams.perPage ?? 12)
  const [open, setOpen] = useState(false)

  const [rowId, setRowId] = useState(null)
  const dispatch = useDispatch()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setQueryParams({
      ...queryParams,
      page: newPage + 1,
    })
  }

  const handleChangeOrder = (event, newOrder, dir = 'ASC') => {
    event.preventDefault()
    let uqueryParams = queryParams

    if (uqueryParams[`order[${newOrder}]`]) {
      if (uqueryParams[`order[${newOrder}]`] === 'ASC') {
        uqueryParams[`order[${newOrder}]`] = 'DESC'
      } else {
        uqueryParams[`order[${newOrder}]`] = 'ASC'
      }
    } else {
      Object.keys(uqueryParams).map((i) => {
        if (i.indexOf('order') > -1) {
          delete uqueryParams[i]
        }
        return null
      })
      uqueryParams[`order[${newOrder}]`] = dir
    }
    setQueryParams({
      ...uqueryParams,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    const perPage = +event.target.value
    setRowsPerPage(perPage)
    setPage(0)
    setQueryParams({
      ...queryParams,
      perPage: perPage,
      page: 1,
    })
  }


  // dialog z usunieciem - zamkniecie || akcja == CANCEL
  const handleDialogClose = () => {
    setOpen(false)
    setRowId(null)
  }

  // dialog z usunieciem - akcja == OK
  const handleDialogAgreeDelete = () => {
    const url = `${schema.delete.replace('{id}', rowId)}`
    const method = 'DELETE'

    dispatch(requestActions.start(url))

    fetchDataHandleAuthError(
      url,
      method,
      { body: [] },
      () => {
        dispatch(requestActions.success())
        notification(
          'success',
          translate('T_RECORD_DELETED'),
          translate('T_SAVED')
        )
        setPage(0)
        setQueryParams({
          ...queryParams,
          rowdeleted: rowId,
          page: 1,
        })
      },
      (error) => {
        notification(
          'error',
          translate('T_INCORRECT_FORM'),
          translate('T_RECORD_DELETE_ERROR')
        )
      },
      dispatch,
      requestConstants.FAILURE
    )

    handleDialogClose()
  }

  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            {expandData && (
              <TableCell key={'thc-id'}>
                <div className={classes.tableHC}>
                  <span>ID</span>
                </div>
              </TableCell>
            )}
            {mainData
              .slice(0, mainData[mainData.length - 1].name)
              .map((x, i) => {
                const xkey = x.key.substring(1)
                return (
                  <TableCell key={`thc-${i}`}>
                    <div className={classes.tableHC}>
                      <Tooltip title={translate('T_SORT_ASC_DESC')}>
                        <Link
                          href="#"
                          onClick={(e) => {
                            handleChangeOrder(e, xkey)
                          }}
                          rel="noreferrer"
                          color="inherit"
                        >
                          <span style={{ verticalAlign: 'middle' }}>
                            {x.name}
                          </span>
                          {queryParams[`order[${xkey}]`] &&
                            queryParams[`order[${xkey}]`] === 'DESC' && (
                              <ArrowDropDownIcon
                                style={{ verticalAlign: 'middle' }}
                              />
                            )}
                          {queryParams[`order[${xkey}]`] &&
                            queryParams[`order[${xkey}]`] === 'ASC' && (
                              <ArrowDropUpIcon
                                style={{ verticalAlign: 'middle' }}
                              />
                            )}
                        </Link>
                      </Tooltip>
                    </div>
                  </TableCell>
                )
              })}
            <TableCell key={'thc-edit'}>
              <div className={classes.tableHC}>
                <span></span>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading &&
            [...Array(rowsPerPage)].map((i, key) => (
              <TableRow key={key}>
                <TableCell colSpan={9} key={key}>
                  <Skeleton animation="wave" />
                </TableCell>
              </TableRow>
            ))}
          {error && (
            <TableRow>
              <TableCell colSpan={9} style={{ padding: 0 }}>
                <Alert severity="error">
                  some error occurred, while fetching api
                </Alert>
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            data &&
            data.map((row, index) => {
              return (
                <React.Fragment key={`${index}_frag`}>
                  <TableRow key={`${index}_row`}>
                    <TableCell key={`${index}_0`}>
                      {row.Therapist.firstName} {row.Therapist.lastName}
                    </TableCell>

                    <TableCell key={`${index}_1`}>
                      {moment(row.createdAt).format('YYYY-MM-DD')}
                    </TableCell>

                    <TableCell key={`${index}_2`}>
                      {row.Therapist.HealthCenter.name}
                    </TableCell>

                    <TableCell key={`${index}_3`}>
                      <StatSwitch
                        value={row.therapistConfirmed}
                        fieldName="therapistConfirmed"
                        apiUrl={schema.set.replace('{id}', row.id)}
                      />
                    </TableCell>

                    <TableCell key={`${index}_4`}>
                      <StatSwitch
                        value={row.parentConfirmed}
                        fieldName="parentConfirmed"
                        apiUrl={schema.set.replace('{id}', row.id)}
                      />
                    </TableCell>

                    <TableCell key={`${index}_5`} className={classes.icons}>
                      <Tooltip title={translate('T_EDIT')}>
                        <Link
                          component={RouterLink}
                          to={routePath.childTherapies.edit(
                            row.Child.id,
                            row.id
                          )}
                          rel="noreferrer"
                        >
                          <EditIcon />
                        </Link>
                      </Tooltip>

                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })}
        </TableBody>
      </Table>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[1, 2, 12]}
          labelRowsPerPage={translate('T_SHOW_ON_PAGE')}
          component="div"
          count={dataLength !== undefined ? dataLength : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <AlertDialog
        key="del-dlg"
        open={open}
        handleClose={handleDialogClose}
        handleAgree={() => {
          handleDialogAgreeDelete()
        }}
        title={translate('T_ALERT_DELETE_TITLE')}
        text={translate('T_ALERT_DELETE_TEXT')}
      />
    </React.Fragment>
  )
}
