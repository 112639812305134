import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { generateRoutes } from '_helpers/generateRoutes'
import { generateBreadcrumbs } from '_helpers/generateBreadcrumbs'
import { Assessment, Settings } from '@material-ui/icons'
import { NotFound } from 'pages/NotFound'
import { translate } from '_helpers/translate'
import { prefixPaths } from '_helpers/prefixPaths'
import ConfigSettings from './ConfigSettings'
import ConfigCokolwiek from './ConfigCokolwiek'
import { routePath } from '_constants/routes.constants.js'
import { Page } from 'pages/Page'

export const Config = (props) => {
    
  const { match, location } = props
  
  const routes = () => [
    {
      title: translate('T_CONFIG'),
      type: Redirect,
      from: '/',
      to: routePath.config.settings,
      exact: true,
    },
    {
      title: translate('T_CONFIG_SETTINGS'),
      type: Route,
      render: () => <ConfigSettings/>,
      path: routePath.config.settings,
      exact: true,
      icon: Settings,
    },
    {
      title: translate('T_CONFIG_COKOLWIEK'),
      type: Route,
      render: () => <ConfigCokolwiek/>,
      path: routePath.config.cokolwiek,
      exact: true,
      icon: Assessment,
    },
    {
      type: Route,
      component: NotFound,
      path: '/*',
    },
  ]
  
  const menu = () => [
      routes()[1], 
      routes()[2]
  ]
  
  return (
      <Page
        menu={prefixPaths(match.url, menu())}        
        breadcrumbs={generateBreadcrumbs( 
          location.pathname, 
          prefixPaths(match.url, routes(), ['path', 'from']) 
        )} 
        routes={generateRoutes(
          prefixPaths(match.url, routes(), ['path', 'from', 'to'])
        )}
      />
  )
}
