import { translations } from 'translations'
import { langService } from '_services'

export const translate = (
  message,
  values = [],
  locale = langService.current()
) =>
  Object.keys(translations[locale]).includes(message)
    ? values.length
      ? translations[locale][message]
          .split('%')
          .map((value, index) => value + (values[index] ? values[index] : ''))
          .join('')
      : translations[locale][message]
    : message
