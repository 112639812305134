import React from 'react'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { IconButton } from '@material-ui/core'
import { useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'

export const UserMenu = () => {

  const profile = useSelector((state) => state.user.profile)


  return (
    <>
      {profile && (
          <Tooltip title={profile?.firstName + ' ' + profile?.lastName}>
            <IconButton
              aria-label="user profile"
              aria-controls="user-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Tooltip>
      )}

    </>
  )
}
