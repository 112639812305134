import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { generateBreadcrumbs } from '_helpers/generateBreadcrumbs'
import { SupervisorAccount, VideogameAsset, Category, Widgets, EmojiEmotions  } from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'
import { translate } from '_helpers/translate'

import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { AccessDenied } from 'pages/AccessDenied'

import { List } from './List'
import { Addto } from './Addto'
import { Editto } from './Editto'

import { List as PracticesList } from './Practices/List'
import { Addto as PracticesAddto } from './Practices/Addto'
import { Editto as PracticesEditto } from './Practices/Editto'

import { List as ElementsList } from './Elements/List'
import { Addto as ElementsAddto } from './Elements/Addto'
import { Editto as ElementsEditto } from './Elements/Editto'

import { List as PracticeCategoriesList } from './PracticeCategories/List'
import { Addto as PracticeCategoriesAddto } from './PracticeCategories/Addto'
import { Editto as PracticeCategoriesEditto } from './PracticeCategories/Editto'

import { List as GameEmotionsList } from './GameEmotions/List'
import { Addto as GameEmotionsAddto } from './GameEmotions/Addto'
import { Editto as GameEmotionsEditto } from './GameEmotions/Editto'

import { routePath } from '_constants/routes.constants.js'

const routes = () => [
  {
    title: translate('T_GAMES_AND_GAME_PRACTICES'),
    type: Route,
    render: () => <List />,
    path: routePath.games.list(''),
    exact: true,
    icon: VideogameAsset,
  },
  {
    title: translate('T_GAME_EDIT'),
    type: Route,
    component: Editto,
    path: routePath.games.edit(':id', ''),
    exact: true,
  },
  {
    title: translate('T_GAME_ADD'),
    type: Route,
    component: Addto,
    path: routePath.games.add(''),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_GAMES'),
    type: Redirect,
    from: '/',
    to: routePath.games.list(''),
    exact: true,
  },

  // GAME KITS
  {
    title: translate('T_GAME_PRACTICES_LIST'),
    type: Route,
    render: ({ match }) => <PracticesList match={match} />,
    path: routePath.practices.list(':gameId', '/list/practices'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_GAME_PRACTICE_EDIT'),
    type: Route,
    component: PracticesEditto,
    path: routePath.practices.edit(':gameId', ':id', '/list/practices'),
    exact: true,
  },
  {
    title: translate('T_GAME_PRACTICE_ADD'),
    type: Route,
    component: PracticesAddto,
    path: routePath.practices.add(':gameId', '/list/practices'),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_GAME_PRACTICES'),
    type: Redirect,
    from: '/',
    to: routePath.practices.list(':gameId', '/list/practices'),
    exact: true,
  },

  // GAME ELEMENTS
  {
    title: translate('T_GAME_ELEMENTS_LIST'),
    type: Route,
    render: ({ match }) => <ElementsList match={match} />,
    path: routePath.elements.list(''),
    exact: true,
    icon: Widgets,
  },
  {
    title: translate('T_GAME_ELEMENT_EDIT'),
    type: Route,
    component: ElementsEditto,
    path: routePath.elements.edit(':id', ''),
    exact: true,
  },
  {
    title: translate('T_GAME_ELEMENT_ADD'),
    type: Route,
    component: ElementsAddto,
    path: routePath.elements.add(''),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_GAME_ELEMENTS'),
    type: Redirect,
    from: '/',
    to: routePath.elements.list(''),
    exact: true,
  },

  // GAME KIT CATEGORIES
  {
    title: translate('T_GAME_PRACTICE_CATEGORY_LIST'),
    type: Route,
    render: ({ match }) => <PracticeCategoriesList match={match} />,
    path: routePath.practiceCategories.list(''),
    exact: true,
    icon: Category,
  },
  {
    title: translate('T_GAME_PRACTICE_CATEGORY_EDIT'),
    type: Route,
    component: PracticeCategoriesEditto,
    path: routePath.practiceCategories.edit(':id', ''),
    exact: true,
  },
  {
    title: translate('T_GAME_PRACTICE_CATEGORY_ADD'),
    type: Route,
    component: PracticeCategoriesAddto,
    path: routePath.practiceCategories.add(''),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_GAME_PRACTICE_CATEGORIES'),
    type: Redirect,
    from: '/',
    to: routePath.practiceCategories.list(''),
    exact: true,
  },

  // GAME_EMOTIONS
  {
    title: translate('T_GAME_EMOTION_LIST'),
    type: Route,
    render: ({ match }) => <GameEmotionsList match={match} />,
    path: routePath.gameEmotions.list(''),
    exact: true,
    icon: EmojiEmotions,
  },
  {
    title: translate('T_GAME_EMOTION_EDIT'),
    type: Route,
    component: GameEmotionsEditto,
    path: routePath.gameEmotions.edit(':id', ''),
    exact: true,
  },
  {
    title: translate('T_GAME_EMOTION_ADD'),
    type: Route,
    component: GameEmotionsAddto,
    path: routePath.gameEmotions.add(''),
    exact: true,
    icon: SupervisorAccount,
  },
  {
    title: translate('T_GAME_EMOTIONS'),
    type: Redirect,
    from: '/',
    to: routePath.gameEmotions.list(''),
    exact: true,
  },

  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

const leftMenu = () => [
  routes()[0], // lista gier
  routes()[12], // lista kategorii układów gier
  routes()[8], // lista elementów gier
  routes()[16], // lista emocji
]

export const Games = (props) => {
  const { match, location } = props

  if (hasRole(authConstants.ROLE_SUPER_ADMIN)) {
    return (
      <Page
        menu={prefixPaths(match.url, leftMenu())}
        routes={generateRoutes(
          prefixPaths(match.url, routes(), ['path', 'from', 'to'])
        )}
        breadcrumbs={generateBreadcrumbs(
          location.pathname,
          prefixPaths(match.url, routes(), ['path', 'from'])
        )}
      />
    )
  } else {
    return <AccessDenied />
  }
}
