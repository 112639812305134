import React from 'react'
import { useSelector } from 'react-redux'

import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'

import { Addto } from './Addto'
import { NotFound } from 'pages/NotFound'

import * as inquirySchema from '_schema/inquiry'

export const Editto = ({ match }) => {
  const {
    params: { inquiryId },
  } = match

  // get data for user
  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, inquiryId]
  const apipoint = inquirySchema.inquiries.get.replace('{id}', inquiryId)

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${apipoint}`,
    options,
    args
  )

  if (res.state.isLoading === false) {
    if (res.state.data)
      return <Addto match={match} dataResource={res.state.data} />
    else return <NotFound />
  } else return <div></div>
}
