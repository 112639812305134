import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { requestActions } from '_actions'
import { requestConstants } from '_constants'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { FormHelperText } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Save } from '@material-ui/icons'
import { translate } from '_helpers/translate'
import { useStyles } from 'components/form/FormStyles'

export const MediaUpload = ({ mediaType, uploadUrl, uploadName, photoUrl, audioUrl, videoUrl }) => {
  const audioRef = React.useRef()
  const videoRef = React.useRef()

  const classes = useStyles()
  const dispatch = useDispatch()


  const [state, setState] = useState({
    values: {},
    errors: {},
    validators: {},
    errorMessageSetters: {},
    invalid: 0,
    isProcessing: false,
    mediaFile: '',
    photoUrl: photoUrl,
    audioUrl: audioUrl,
    videoUrl: videoUrl,
  })

  const fields = {
    title: {
      type: 'file',
      validate: ['required'],
      label: translate('T_FILE_UPLOAD_LABEL'),
      hint: translate('T_FILE_UPLOAD_HINT'),
    },
  }

  useEffect(() => {
    if (mediaType === 'TYPE_AUDIO') {
      audioRef.current.pause()
      audioRef.current.load()
    }

    if (mediaType === 'TYPE_VIDEO') {
      videoRef.current.pause()
      videoRef.current.load()
    }
  }, [state])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (
      Object.keys(state.validators).length &&
      !Object.keys(state.validators).reduce(
        (status, name) =>
          state.validators[name].validator(
            ...Object.keys(state.values)
              .filter((item) => state.validators[name].values.includes(item))
              .map((item) => state.values[item])
          ) && status,
        true
      )
    ) {
      return
    }

    setState((state) => ({ ...state, isProcessing: true }))

    var formData = new FormData()
    formData.append(uploadName, state.mediaFile)

    fetchDataHandleAuthError(
      uploadUrl,
      'POST',
      { body: formData },
      (resp) => {
        setState((state) => ({
          ...state,
          isProcessing: false,
          photoUrl: resp.photoThumbs.full ? resp.photoThumbs.full : null,
          audioUrl: resp.audioUrl,
          videoUrl: resp.videoUrl,
        }))

        dispatch(requestActions.success())
        notification('success', translate('T_FILE_UPLOADED_SUCCESS'), translate('T_UPLOADED'))
      },

      (error) => {
        setState((state) => ({ ...state, isProcessing: false }))
        error.response.violations.map((item) => state.errorMessageSetters[item.propertyPath](translate(item.message)))
        notification(
          'error',
          error.response.violations.length ? translate('T_INCORRECT_FORM') : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      requestConstants.FAILURE,
      { 'Content-Type': 'multipart/form-data' }
    )
  }

  const handleMediaFileChange = (event) => {
    const files = Array.from(event.target.files)

    setState((state) => ({ ...state, mediaFile: files[0] }))
  }



  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <form onSubmit={handleSubmit} className={classes.root}>
          <Grid container spacing={3}>
            {Object.keys(fields).map((name, key) => {
              if (!fields[name]) return null

              return (
                <Grid item xs={4} key={`${key}_grid`}>
                  {fields[name].type === 'file' && (
                    <div>
                      <FormHelperText>
                        {fields[name].label + (fields[name].validate.includes('required') ? ' *' : '')}
                      </FormHelperText>

                      <input type="file" onChange={handleMediaFileChange} />
                      <FormHelperText>{fields[name].hint}</FormHelperText>
                    </div>
                  )}
                </Grid>
              )
            })}

            <Grid item xs={4} key="preview_grid">
              {mediaType === 'TYPE_PHOTO' && (
                <img src={process.env.REACT_APP_API_ENTRYPOINT + state.photoUrl} height="150" alt="" />
              )}

              {mediaType === 'TYPE_AUDIO' && (
                <audio height="150" controls ref={audioRef}>
                  <source src={process.env.REACT_APP_API_ENTRYPOINT + state.audioUrl} type="audio/mp3" />
                </audio>
              )}

              {mediaType === 'TYPE_VIDEO' && (
                <video height="150" controls ref={videoRef}>
                  <source src={process.env.REACT_APP_API_ENTRYPOINT + state.videoUrl} type="video/mp4" />
                </video>
              )}
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<Save />}
                className={classes.save}
                disabled={state.isProcessing || !!state.invalid}
              >
                {translate('T_SUBMIT_UPLOAD')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </React.Fragment>
  )
}
