import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchData } from '_helpers/fetchData'
import * as professionSchema from '_schema/profession'
import * as provinceSchema from '_schema/province'
import * as healthCenterSchema from '_schema/healthCenter'
import TableList from './TableList'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { makeStyles } from '@material-ui/core/styles'
import { setProps } from '_helpers/tableProps'
import { translate } from '_helpers/translate'
import { users } from '_schema/user'
import { routePath } from '_constants/routes.constants.js'

const useStyles = makeStyles({
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-18px',
    marginBottom: '20px',
  },
  professionSelect: {
    minWidth: '150px',
    marginRight: '10px',
  },
  healthCenterSelect: {
    minWidth: '180px',
    marginRight: '10px',
  },
  statSelect: {
    minWidth: '130px',
    marginRight: '10px',
  },
  isAcceptedSelect: {
    minWidth: '150px',
    marginRight: '10px',
  },
  rolesSelect: {
    minWidth: '150px',
    marginRight: '10px',
  },
})

const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}${users.list}`
const typingTimeout = 1000

const listCols = () => {
  return {
    login: 'LOGIN',
    firstName: translate('T_FIRSTNAME'),
    lastName: translate('T_SURNAME'),
    email: translate('T_EMAIL'),
    roles: translate('T_ROLES'),
    isPremium: translate('T_IS_PREMIUM'),
    accepted: translate('T_ACCEPTED'),
    stat: translate('T_STAT'),
    displayCollumn: 8, //extra, tell how many kolumns show
  }
}

const mainData = () => {
  return setProps(listCols())
}

export const List = () => {
  const [state, setState] = useState({
    professions: [],
    provinces: [],
    healthCenters: [],
  })

  useEffect(() => {
    // zaciągnięcie specjalizacji do selecta
    fetchData(
      professionSchema.profession.list,
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      setState((state) => ({ ...state, professions: response.content }))
    })
    // zaciągnięcie województw do selecta
    fetchData(
      provinceSchema.province.list,
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      setState((state) => ({ ...state, provinces: response.content }))
    })
    // zaciągnięcie ośrodków zdrowia do selecta
    fetchData(
      healthCenterSchema.healthCenter.list,
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      setState((state) => ({ ...state, healthCenters: response.content }))
    })
  }, []) // bez parametrów odpala sie tylko raz

  const listqueryParams =
    localStorage.listUsersQueryParams === undefined
      ? {
          search: '',
          'HealthCenter.id[]': [],
          'Professions.id[]': [],
          accepted: '',
          stat: '',
          roles: '',

          page: 1,
          perPage: 12,
          'order[name]': 'ASC',
        }
      : JSON.parse(localStorage.listUsersQueryParams)

  const classes = useStyles()
  const auth = useSelector((state) => state.auth)

  const [queryParams, setQueryParams] = useState(listqueryParams)
  const [typingState, setTypingState] = useState({
    timeoutId: null,
  })

  const [searchText, setSearchText] = useState(listqueryParams.search ?? '')
  const [professionIds, setProfessionIds] = useState(
    listqueryParams['Professions.id[]'] ?? []
  )
  const [healthCenterIds, setHealthCenterIds] = useState(
    listqueryParams['HealthCenter.id[]'] ?? ''
  )
  const [isAccepted, setIsAccepted] = useState(listqueryParams.accepted ?? '')
  const [stat, setStat] = useState(listqueryParams.stat ?? '')
  const [roles, setRoles] = useState(listqueryParams.roles ?? '')

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)

  useEffect(() => {
    localStorage.listUsersQueryParams = JSON.stringify(queryParams)
    return () => {
      // cleanup
    }
  }, [queryParams])

  const handleSearchTextChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  const handleProfessionChange = (e) => {
    const professionIds = e.target.value
    setProfessionIds(professionIds)
    setQueryParams({
      ...queryParams,
      'Professions.id[]': professionIds,
      page: 1,
    })
  }

  const handleHealthCenterChange = (e) => {
    const healthCenterIds = e.target.value
    setHealthCenterIds(healthCenterIds)
    setQueryParams({
      ...queryParams,
      'HealthCenter.id[]': healthCenterIds,
      page: 1,
    })
  }

  const handleIsAcceptedChange = (e) => {
    const isAccepted = e.target.value
    setIsAccepted(isAccepted)
    setQueryParams({
      ...queryParams,
      accepted: isAccepted,
      page: 1,
    })
  }

  const handleStatChange = (e) => {
    const stat = e.target.value
    setStat(stat)
    setQueryParams({
      ...queryParams,
      stat: stat,
      page: 1,
    })
  }

  const handleRolesChange = (e) => {
    const roles = e.target.value
    setRoles(roles)
    setQueryParams({
      ...queryParams,
      roles: roles,
      page: 1,
    })
  }

  return (
    <React.Fragment>
      <div className={classes.gridParent}>
        <FormControl className={classes.rolesSelect}>
          <InputLabel>{translate('T_ROLES')}</InputLabel>
          <Select value={roles} onChange={handleRolesChange}>
            <MenuItem value="">{translate('T_ALL')}</MenuItem>
            <MenuItem value="ROLE_MANAGER">
              {translate('T_ROLE_MANAGER')}
            </MenuItem>
            <MenuItem value="ROLE_THERAPIST">
              {translate('T_ROLE_THERAPIST')}
            </MenuItem>
            <MenuItem value="ROLE_PARENT">
              {translate('T_ROLE_PARENT')}
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.professionSelect}>
          <InputLabel>{translate('T_PROFESSIONS')}</InputLabel>
          <Select
            multiple={true}
            value={professionIds}
            onChange={handleProfessionChange}
          >

            {[...state.professions].map((item) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <FormControl className={classes.healthCenterSelect}>
          <InputLabel>{translate('T_HEALTH_CENTER')}</InputLabel>
          <Select
            multiple={true}
            value={healthCenterIds}
            onChange={handleHealthCenterChange}
          >
            <MenuItem value="">{translate('T_ALL')}</MenuItem>
            <MenuItem value={-1}>{translate('T_NOT_ASSIGNED')}</MenuItem>
            {[...state.healthCenters].map((item) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <FormControl className={classes.isAcceptedSelect}>
          <InputLabel>{translate('T_IS_ACCEPTED')}</InputLabel>
          <Select value={isAccepted} onChange={handleIsAcceptedChange}>
            <MenuItem value="">{translate('T_ALL')}</MenuItem>
            <MenuItem value={true}>{translate('T_ACCEPTED')}</MenuItem>
            <MenuItem value={false}>{translate('T_NOT_ACCEPTED')}</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.statSelect}>
          <InputLabel>{translate('T_STAT')}</InputLabel>
          <Select value={stat} onChange={handleStatChange}>
            <MenuItem value="">{translate('T_ALL')}</MenuItem>
            <MenuItem value={true}>{translate('T_ACTIVE')}</MenuItem>
            <MenuItem value={false}>{translate('T_INACTIVE')}</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label={translate('T_SEARCH')}
          value={searchText}
          onChange={handleSearchTextChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>

      <TableList
        data={res.state.data ? res.state.data?.content : []}
        isLoading={res.state.isLoading}
        error={res.state.error}
        mainData={mainData()}
        expandData={false}
        dataLength={res.state.data?.totalItems}
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
      <div>
        <Button
          component={RouterLink}
          to={routePath.users.add(null, 'ROLE_THERAPIST')}
          variant="contained"
          color="primary"
          role="ROLE_THERAPIST"
          >{translate('T_ADD_USER_THERAPIST')}
        </Button>
          &nbsp;&nbsp;
        <Button
          component={RouterLink}
          to={routePath.users.add(null, 'ROLE_MANAGER')}
          variant="contained"
          color="primary"
          role="ROLE_MANAGER"
          >{translate('T_ADD_USER_MANAGER')}
        </Button>
      </div>
    </React.Fragment>
  )
}
