import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Skeleton from '@material-ui/lab/Skeleton'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import { useSelector, useDispatch } from 'react-redux'
import { Title } from 'components/Title'
import { authHeader } from '_helpers/authHeader'
import { get } from '_helpers/tableProps'
import { useFetch } from '_helpers/useFetch'
import Moment from 'react-moment'
import { translate } from '_helpers/translate'

const useStyles = makeStyles((theme) => ({
  seeMore: {
    paddingTop: theme.spacing(3),
    marginTop: 'auto',
  },
}))

export default function HomeBox({ match, title, keys, header, linkMore, queryParams }) {
  const classes = useStyles()

  const auth = useSelector((state) => state.auth)

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch, match, queryParams]

  const res = useFetch(`${process.env.REACT_APP_API_ENTRYPOINT}/${match}`, options, args, queryParams)

  return (
    <React.Fragment>
      <Title>
        {title} ({res.state.data?.totalItems})
      </Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            {header.map((name, i) => (
              <TableCell key={`hb-head-${i}`}> {name} </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {res.state.isLoading &&
            [...Array(6)].map((i, r) => (
              <TableRow key={r}>
                <TableCell colSpan={4}>
                  <Skeleton animation="wave" />
                </TableCell>
              </TableRow>
            ))}
          {res.state.error && (
            <TableRow>
              <TableCell colSpan={4} style={{ padding: 0 }}>
                <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
              </TableCell>
            </TableRow>
          )}

          {!res.state.isLoading && res.state.data?.content &&
            res.state.data.content.map((row) => (
              <TableRow key={row.id}>
                {keys.map((e, i) => {
                  if (e === 'gender') {
                    return (
                      <TableCell key={i}>
                        {row.gender === 'M' && translate('T_GENDER_BOY')}
                        {row.gender === 'F' && translate('T_GENDER_GIRL')}
                      </TableCell>
                    )
                  }
                  if (e === 'birthDate') {
                    return (
                      <TableCell key={i}>
                        <Moment format="DD.MM.YYYY">{get(row, e, '')}</Moment>
                      </TableCell>
                    )
                  }

                  return <TableCell key={i}>{get(row, e, '')}</TableCell>
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Button component={Link} to={linkMore} variant="outlined" color="primary">
          pokaż wszystkie
        </Button>
      </div>
    </React.Fragment>
  )
}
