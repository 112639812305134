import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchData } from '_helpers/fetchData'
import * as practiceCategorySchema from '_schema/practiceCategory'
import TableList from './TableList'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { makeStyles } from '@material-ui/core/styles'
import { setProps } from '_helpers/tableProps'
import { translate } from '_helpers/translate'
import { practice as schema } from '_schema/practice'
import { routePath } from '_constants/routes.constants.js'

const useStyles = makeStyles({
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-18px',
    marginBottom: '20px',
  },
  practiceCategorySelect: {
    minWidth: '210px',
    marginRight: '10px',
  },
  practiceTypeSelect: {
    minWidth: '210px',
    marginRight: '10px',
  },
})

const typingTimeout = 1000

const listCols = () => {
  return {
    title: translate('T_GAME_PRACTICE_TITLE'),
    text: translate('T_DESCRIPTION'),
    'PracticeCategory.title': translate('T_CATEGORY'),
    stagesCount: translate('T_STAGES_COUNT'),
    hasInquiriesAssigned: translate('T_INQUIRIES_ASSIGNED'),
    stat: translate('T_STAT'),
    displayCollumn: 6, //extra, tell how many kolumns show
  }
}

const mainData = () => {
  return setProps(listCols())
}

export const List = ({ match }) => {

  const [state, setState] = useState({
    practiceCategories: [],
  });

  useEffect(() => {
    // zaciągnięcie kategorii ćwiczeń do selecta
    fetchData(
      practiceCategorySchema.practiceCategory.list,
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      setState((state) => ({ ...state, practiceCategories: response.content }))
    });
  }, []) // bez parametrów odpala sie tylko raz


  const {
    params: { gameId },
  } = match

  const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}${schema.listAllByGame}`.replace(
    '{gameId}',
    gameId
  )

  const listqueryParams =
    localStorage.listPracticesQueryParams === undefined
      ? {
          search: '',
          'PracticeCategory.id[]': [],
          'practiceType[]': [],
          page: 1,
          perPage: 12,
          'order[name]': 'ASC',
        }
      : JSON.parse(localStorage.listPracticesQueryParams)

  const classes = useStyles()
  const auth = useSelector((state) => state.auth)

  const [queryParams, setQueryParams] = useState(listqueryParams)
  const [typingState, setTypingState] = useState({ timeoutId: null })

  const [searchText, setSearchText] = useState(listqueryParams.search ? listqueryParams.search : '');
  const [practiceCategoryIds, setPracticeCategoryIds] = useState( listqueryParams['PracticeCategory.id[]'] ?? '' )
  const [practiceTypes, setPracticeTypes] = useState( listqueryParams['practiceType[]'] ?? '' )

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)

  useEffect(() => {
    localStorage.listPracticesQueryParams = JSON.stringify(queryParams)
    return () => {
      // cleanup
    }
  }, [queryParams])

  const handlePracticeCategoryChange = (e) => {
    const practiceCategoryIds = e.target.value
    setPracticeCategoryIds(practiceCategoryIds)
    setQueryParams({
      ...queryParams,
      'PracticeCategory.id[]': practiceCategoryIds,
      page: 1,
    })
  }

  const handlePracticeTypeChange = (e) => {
    const practiceTypes = e.target.value
    setPracticeTypes(practiceTypes)
    setQueryParams({
      ...queryParams,
      'practiceType[]': practiceTypes,
      page: 1,
    })
  }

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  return (
    <React.Fragment>
      <div className={classes.gridParent}>

        <FormControl className={classes.practiceCategorySelect}>
          <InputLabel>{translate('T_PRACTICE_CATEGORY')}</InputLabel>
          <Select
            multiple={false}
            value={practiceCategoryIds}
            onChange={handlePracticeCategoryChange}
          >
            <MenuItem value="">{translate('T_ALL')}</MenuItem>
            {[...state.practiceCategories].map((item) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <FormControl className={classes.practiceTypeSelect}>
          <InputLabel>{translate('T_PRACTICE_TYPE')}</InputLabel>
          <Select
            multiple={false}
            value={practiceTypes}
            onChange={handlePracticeTypeChange}
          >
            <MenuItem value="">{translate('T_ALL')}</MenuItem>
            <MenuItem value="PRACTICE_TYPE_TEMPLATE">{translate('T_PRACTICE_TYPE_TEMPLATE')}</MenuItem>
            <MenuItem value="PRACTICE_TYPE_INQUIRY">{translate('T_PRACTICE_TYPE_INQUIRY')}</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label={translate('T_SEARCH')}
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <TableList
        data={
          res.state.data
            ? res.state.data.content
              ? res.state.data.content
              : []
            : []
        }
        isLoading={res.state.isLoading}
        error={res.state.error}
        mainData={mainData()}
        expandData={false}
        dataLength={res.state.data?.totalItems}
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
      <div>
        <Button
          component={RouterLink}
          to={routePath.practices.add(gameId)}
          variant="contained"
          color="primary"
        >
          {translate('T_GAME_PRACTICE_ADD')}
        </Button>
      </div>
    </React.Fragment>
  )
}
