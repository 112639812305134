export const processError = (error) =>
  error.response
    ? error
    : {
        response: {
          title: 'Error',
          detail: error.toString(),
          violations: [],
        },
      }
