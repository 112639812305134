import React from 'react'
import { Router, Switch } from 'react-router-dom'
import ReactNotification from 'react-notifications-component'
import { hot } from 'react-hot-loader'
import { history } from '_helpers/history'
import { isLogged } from '_helpers/isLogged'
import routes from 'routes'
import { generateRoutes } from '_helpers/generateRoutes'
import { store } from '_store'
import { authActions } from '_actions/index'

isLogged(store.dispatch, authActions.restore, authActions.logout)

const App = () => {
  return (
    <>
      <ReactNotification />
      <Router history={history}>
        <div className="App">
          <Switch>{generateRoutes(routes())}</Switch>
        </div>
      </Router>
    </>
  )
}

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App
