import { Route, Redirect } from 'react-router-dom'
import {
  Home,
  LoginContainer,
  NotFound,
  ResetPassword,
  ForgotPassword,
} from 'pages'

import { AnonymousRoute } from 'components/AnonymousRoute'
import { PrivateRoute } from 'components/PrivateRoute'
import { routePath } from '_constants/routes.constants'

import { Superadmins } from './pages/Superadmins'
import { Users } from './pages/Users'

import { Childs } from './pages/Childs'
import { HealthCenters } from './pages/HealthCenters'
import { Inquiries } from './pages/Inquiries'
import { Professions } from './pages/Professions'
import { Games } from './pages/Games'
import { Config } from './pages/Config'

const routes = () => {
  return [
    {
      type: PrivateRoute,
      component: Home,
      path: routePath.home,
    },
    {
      type: AnonymousRoute,
      component: LoginContainer,
      path: routePath.login,
      exact: true,
    },
    {
      type: PrivateRoute,
      component: Superadmins,
      path: routePath.superadmins.main,
    },
    {
      type: PrivateRoute,
      component: Users,
      path: routePath.users.main,
    },
    {
      type: PrivateRoute,
      component: Childs,
      path: routePath.childs.main,
    },
    {
      type: PrivateRoute,
      component: HealthCenters,
      path: routePath.healthCenter.main,
    },
    {
      type: PrivateRoute,
      component: Professions,
      path: routePath.profession.main,
    },
    {
      type: PrivateRoute,
      component: Inquiries,
      path: routePath.inquiries.main,
    },
    {
      type: PrivateRoute,
      component: Games,
      path: routePath.games.main,
    },
    {
      type: PrivateRoute,
      component: Config,
      path: routePath.config.main,
    },
    {
        
      type: AnonymousRoute,
      component: ForgotPassword,
      path: routePath.forgotpassword,
    },
    {
      type: AnonymousRoute,
      component: ResetPassword,
      path: routePath.resetpassword,
    },
    {
      type: Redirect,
      from: '/',
      to: routePath.home,
      exact: true,
    },
    {
      type: Route,
      component: NotFound,
      path: '*',
    },
  ]
}

export default routes
