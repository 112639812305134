import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useHistory } from 'react-router-dom'
import { translate } from '_helpers/translate'
import { notification } from '_helpers/notification'
import { Copyright } from 'components/Copyright'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  spinner: {
    height: '24px',
    animationName: '$spin',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  formError: {
    display: 'block',
    color: 'red',
  },
}))

export const ResetPassword = () => {
  const classes = useStyles()

  const initialState = {
    password: '',
    isSubmitting: false,
    errorMessage: null,
    errorRepeat: false,
  }

  const [data, setData] = React.useState(initialState)
  const history = useHistory()

  const handleInputChange = (event) => {
    const eRepeat = event.target.value !== data.passwordrepeat ? true : false
    setData({
      ...data,
      errorMessage: null,
      errorRepeat: eRepeat,
      [event.target.name]: event.target.value,
    })
  }

  const handleInputChangeRepeat = (event) => {
    const eRepeat = data.password !== event.target.value ? true : false
    setData({
      ...data,
      errorMessage: null,
      errorRepeat: eRepeat,
      [event.target.name]: event.target.value,
    })
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    const eRepeat = data.password !== data.passwordrepeat ? true : false
    setData({
      ...data,
      isSubmitting: !eRepeat,
      errorMessage: null,
      errorRepeat: eRepeat,
    })
    if (!eRepeat)
      fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${window.location.pathname}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            plainPassword: data.password,
          }),
        }
      )
        .then((response) => {
          if (response.ok) {
            history.push('/')
            notification(
              'success',
              translate('T_SAVED'),
              translate('T_PASSWORD_CHANGED')
            )
          } else if (response.status === 400 || response.status === 403) {
            return response.json()
          } else throw response
        })
        .then((result) => {
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: result.violations
              ? translate(result.violations[0].message)
              : result.detail
              ? translate(result.detail)
              : 'Error',
          })
        })
        .catch((error) => {
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: error.message || error.statusText,
          })
        })
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {translate('T_PASSWORD_TITLE')}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label={translate('T_PASSWORD_LABEL')}
                type="password"
                id="password"
                autoComplete="current-password"
                value={data.password}
                onChange={handleInputChange}
                helperText={
                  data.errorMessage
                    ? data.errorMessage
                    : data.isSubmitting
                    ? ''
                    : translate('T_PASSWORD_HINT')
                }
                error={data.errorMessage ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="passwordrepeat"
                label={translate('T_PASSWORDREPEAT_LABEL')}
                type="password"
                id="passwordrepeat"
                autoComplete="current-password"
                value={data.passwordrepeat}
                onChange={handleInputChangeRepeat}
                helperText={
                  data.errorRepeat
                    ? translate('T_PASSWORDREPEAT_ERROR')
                    : data.isSubmitting
                    ? ''
                    : translate('T_PASSWORDREPEAT_HINT')
                }
                error={data.errorRepeat}
              />

            </Grid>
          </Grid>
          <Button
            disabled={data.isSubmitting}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {data.isSubmitting ? (
              <img
                className={classes.spinner}
                src="/images/logo.svg"
                alt="loading icon"
              />
            ) : (
              translate('T_PASSWORD_RESET')
            )}
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
