import React, { useState, useEffect } from 'react'

import { fetchData } from '_helpers/fetchData'

import TextField from '@material-ui/core/TextField'

import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import TableList from './TableList'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { makeStyles } from '@material-ui/core/styles'

import { setProps } from '_helpers/tableProps'
import { translate } from '_helpers/translate'

import { element as schema } from '_schema/element'
import { game as gameSchema } from '_schema/game'

import { routePath } from '_constants/routes.constants.js'

const useStyles = makeStyles({
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-18px',
    marginBottom: '20px',
  },
  gameSelect: {
    minWidth: '130px',
    marginRight: '10px',
  },
  typeSelect: {
    minWidth: '130px',
    marginRight: '10px',
  },
  statSelect: {
    minWidth: '130px',
    marginRight: '10px',
  },
})

const typingTimeout = 1000

const listCols = () => {
  return {
    thumb: translate('T_THUMB'),
    title: translate('T_NAME'),
    text: translate('T_DESCRIPTION'),
    game: translate('T_GAME'),
    type: translate('T_TYPE'),
    stat: translate('T_STAT'),
    displayCollumn: 6, //extra, tell how many kolumns show
  }
}

const mainData = () => {
  return setProps(listCols())
}

export const List = ({ match }) => {
  const {
    params: { id },
  } = match

  const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}${schema.list}`.replace(
    '{id}',
    id
  )

  const listqueryParams =
    localStorage.listElementsQueryParams === undefined
      ? {
          search: '',
          stat: '',
          gameId: '',

          page: 1,
          perPage: 12,
          'order[name]': 'ASC',
        }
      : JSON.parse(localStorage.listElementsQueryParams)

  const classes = useStyles()
  const auth = useSelector((state) => state.auth)
  const [queryParams, setQueryParams] = useState(listqueryParams)
  const [typingState, setTypingState] = useState({ timeoutId: null })
  const [searchText, setSearchText] = useState(
    listqueryParams.search ? listqueryParams.search : ''
  )
  const [stat, setStat] = useState(
    listqueryParams.stat ? listqueryParams.stat : ''
  )
  const [type, setType] = useState(
    listqueryParams.type ? listqueryParams.type : ''
  )
  const [gameId, setGameId] = useState(listqueryParams.gameId ?? '')
  const [games, setGames] = useState([])

  useEffect(() => {
    fetchData(gameSchema.list, 'GET', {}, (resp) => resp.json(), false).then(
      (response) => {
        setGames(response.content)
      }
    )
  }, []) // bez parametrów odpala sie tylko raz

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)

  useEffect(() => {
    localStorage.listElementsQueryParams = JSON.stringify(queryParams)
    return () => {
      // cleanup
    }
  }, [queryParams])

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  const handleStatChange = (e) => {
    const stat = e.target.value
    setStat(stat)
    setQueryParams({
      ...queryParams,
      stat: stat,
      page: 1,
    })
  }

  const handleTypeChange = (e) => {
    const type = e.target.value
    setType(type)
    setQueryParams({
      ...queryParams,
      type: type,
      page: 1,
    })
  }

  const handleGameChange = (e) => {
    const gameId = e.target.value
    setGameId(gameId)
    setQueryParams({
      ...queryParams,
      gameId: gameId,
      page: 1,
    })
  }

  return (
    <React.Fragment>
      <div className={classes.gridParent}>
        <FormControl className={classes.gameSelect}>
          <InputLabel>{translate('T_GAME')}</InputLabel>
          <Select value={gameId} onChange={handleGameChange}>
            <MenuItem value="">{translate('T_ALL')}</MenuItem>
            {[...games].map((item) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <FormControl className={classes.typeSelect}>
          <InputLabel>{translate('T_TYPE')}</InputLabel>
          <Select value={type} onChange={handleTypeChange}>
            <MenuItem value="">{translate('T_ALL')}</MenuItem>
            <MenuItem value="TYPE_PHOTO">{translate('T_TYPE_PHOTO')}</MenuItem>
            <MenuItem value="TYPE_AUDIO">{translate('T_TYPE_AUDIO')}</MenuItem>
            <MenuItem value="TYPE_VIDEO">{translate('T_TYPE_VIDEO')}</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.statSelect}>
          <InputLabel>{translate('T_STAT')}</InputLabel>
          <Select value={stat} onChange={handleStatChange}>
            <MenuItem value="">{translate('T_ALL')}</MenuItem>
            <MenuItem value={true}>{translate('T_ACTIVE')}</MenuItem>
            <MenuItem value={false}>{translate('T_INACTIVE')}</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label={translate('T_SEARCH')}
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <TableList
        data={
          res.state.data
            ? res.state.data.content
              ? res.state.data.content
              : []
            : []
        }
        isLoading={res.state.isLoading}
        error={res.state.error}
        mainData={mainData()}
        expandData={false}
        dataLength={res.state.data?.totalItems}
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
      <div>
        <Button
          component={RouterLink}
          to={routePath.elements.add(routePath.games.main)}
          variant="contained"
          color="primary"
        >
          {translate('T_GAME_ELEMENT_ADD')}
        </Button>
      </div>
    </React.Fragment>
  )
}
