import React from 'react'
import { useSelector } from 'react-redux'

import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'

import { Addto } from './Addto'
import { NotFound } from 'pages/NotFound'

import { inquiryPractice as schema } from '_schema/inquiryPractice'

export const Editto = ({ match }) => {
  const {
    params: { id },
  } = match

  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: { accept: 'application/json', ...authHeader() },
  }

  const args = [auth.data.token, id]
  const apipoint = schema.get.replace('{id}', id)

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${apipoint}`,
    options,
    args
  )

  if (res.state.isLoading === false) {
    if (res.state.data) {
      return (
        <div>
          <Addto match={match} dataResource={res.state.data} />
        </div>
      )
    } else {
      return <NotFound />
    }
  } else {
    return <div></div>
  }
}
