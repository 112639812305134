import React, { useState, useEffect } from 'react'
import { fetchData } from '_helpers/fetchData'
import moment from 'moment'
import { childInquiry as schema } from '_schema/childInquiry'

export const View = ({ match }) => {
  const {
    params: { id },
  } = match


  const url = schema.get.replace('{id}', id)

  const [inquiryData, setInquiryData] = useState({})

  useEffect(() => {
    fetchData(url, 'GET', {}, (resp) => resp.json(), false).then((response) => {
      setInquiryData(response)
    })
  }, [])

  return (
    <React.Fragment>
      <div>
        <b>Data wypełnienia ankiety:</b>{' '}
        {moment(inquiryData?.createdAt).format('YYYY-MM-DD')}
      </div>

      <div>
        <b>Wynik:</b> {inquiryData?.verdictTitle}
      </div>

      <div>
        <b>Opis:</b> {inquiryData?.verdictText}
      </div>
      <br />
      <div>
        <b>Pytania i odpowiedzi:</b>
        <ul>
          {inquiryData?.Answers &&
            inquiryData.Answers.map((row, key) => {

              return (
                <li key="{row.id}">
                  <b>{row.question}</b> {row.answer} [{row.score}]
                </li>
              )
            })}
        </ul>
      </div>
    </React.Fragment>
  )
}
