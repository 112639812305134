import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Save } from '@material-ui/icons'

import { fetchData } from '_helpers/fetchData'

import { translate } from '_helpers/translate'
import { getProps } from '_helpers/tableProps'
import { notification } from '_helpers/notification'
import { StringType } from 'components/form/StringType'
import { requestActions } from '_actions'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { dataToObj } from '_helpers/tableProps'

import { requestConstants } from '_constants'

import { config as schema } from '_schema/config'

const useStyles = makeStyles((theme) => ({
  seeMore: {
    paddingTop: theme.spacing(3),
    marginTop: 'auto',
  },
}))

export default function ConfigSettings() {
  const classes = useStyles()
  const dispatch = useDispatch()
  
  const id = 1
  
  const [data, setData] = useState(null)
 
  const [state, setState] = useState({
    values: {},
    errors: {},
    validators: {},
    errorMessageSetters: {},
    invalid: 0,
    isProcessing: false,
    method: 'POST',
    url: schema.add,
  })
  
  useEffect(() => {
    fetchData(
      schema.get.replace('{id}', id),
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((resp) => {
      if (resp.id) {
          console.log(resp);
        setData(resp);
        setState((state) => ({ ...state, method: 'PUT' }))
        setState((state) => ({ ...state, url: schema.set.replace('{id}', id) }))
      } else {
        setData({})
      }
    })
  }, []) // bez parametrów odpala sie tylko raz
  
  const fields = {
    name: {
      type: 'string',
      validate: ['required'],
      label: translate('T_NAME_LABEL'),
      hint: translate('T_NAME_HINT'),
    },
    gamesAccessMessage: {
      type: 'string',
      validate: ['required'],
      label: translate('T_CONFIG_GAMES_ACCESS_MESSAGE_LABEL'),
      hint: translate('T_CONFIG_GAMES_ACCESS_MESSAGE_HINT'),
    },
  }
  
  const handleSubmit = (e) => {
    e.preventDefault()

    if (
      Object.keys(state.validators).length &&
      !Object.keys(state.validators).reduce(
        (status, name) =>
          state.validators[name].validator(
            ...Object.keys(state.values)
              .filter((item) => state.validators[name].values.includes(item))
              .map((item) => state.values[item])
          ) && status,
        true
      )
    ) {
      return
    }

    setState((state) => ({ ...state, isProcessing: true }))
    dispatch(requestActions.start(state.url))

    fetchDataHandleAuthError(
      state.url,
      state.method,
      { body: JSON.stringify(dataToObj(state.values)) },
      () => {
        setState((state) => ({ ...state, isProcessing: false }))
        dispatch(requestActions.success())
        notification( 'success', state.method === 'PUT' ? translate('T_RECORD_UPDATED') : translate('T_RECORD_ADDED'), translate('T_SAVED'))
        handleSuccess && handleSuccess(state.values)
      },
      (error) => {
        setState((state) => ({ ...state, isProcessing: false }))
        error.response.violations.map((item) =>
          state.errorMessageSetters[item.propertyPath](translate(item.message))
        )
        notification('error', error.response.violations.length ? translate('T_INCORRECT_FORM') : error.response.detail, error.response.title)
      },
      dispatch,
      requestConstants.FAILURE
    )
  }
  
  const handleSuccess = (resp) => {
    setData(resp)
    setState((state) => ({ ...state, method: 'PUT' }))
    setState((state) => ({ ...state, url: schema.set.replace('{id}', id) }))
  }
  
  const setValue = (name, value) => {
    const isInvalid = value instanceof Error
    setState((state) => ({
      ...state,
      values: { ...state.values, [name]: !isInvalid && value },
      errors: { ...state.errors, [name]: isInvalid },
      invalid:
        state.invalid +
        (!!state.errors[name] === !isInvalid && -1 + isInvalid * 2),
    }))
  }

  const setValidator = (name, validator, values) => {
    setState((state) => ({
      ...state,
      validators: {
        ...state.validators,
        [name]: { validator, values: values || [name] },
      },
    }))
  }

  const setErrorMessageSetter = (name, errorMessageSetter) => {
    setState((state) => ({
      ...state,
      errorMessageSetters: {
        ...state.errorMessageSetters,
        [name]: errorMessageSetter,
      },
    }))
  }
  
  return (
    <React.Fragment>

      <Grid container spacing={0}>
        {data && (
          <form onSubmit={handleSubmit} className={classes.root}>
            <br/>
            <Grid container spacing={1}>
              {Object.keys(fields).map((name, key) => {
                if (!fields[name]) return null

                return (
                  <Grid item xs={12} key={`${key}_grid`}>
                    {fields[name].type === 'string' && (
                      <StringType
                        key={key}
                        name={fields[name].name ? fields[name].name : name}
                        label={fields[name].label}
                        type={fields[name].type}
                        hint={fields[name].hint}
                        value={ data && (fields[name]['resource'] ? getProps(data, fields[name]['resource']) : data[name]) }
                        disabled={ fields[name].disabled === true ? true : state.isProcessing }
                        validators={fields[name].validate || []}
                        setValue={setValue}
                        setValidator={setValidator}
                        setErrorMessageSetter={setErrorMessageSetter}
                      />
                    )}
                  </Grid>
                )
              })}

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<Save />}
                  className={classes.save}
                  disabled={state.isProcessing || !!state.invalid}
                >
                  { translate('T_SAVE')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Grid>

    </React.Fragment>
  )
}
