import React, { useState, useEffect } from 'react'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { fetchData } from '_helpers/fetchData';
import { requestConstants } from '_constants'
import { routePath } from '_constants/routes.constants.js'
import { requestActions } from '_actions'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import { Link as RouterLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { StatSwitch } from 'components/tableList/StatSwitch'
import { dataToObj } from '_helpers/tableProps'
import { notification } from '_helpers/notification'
import { makeStyles } from '@material-ui/core/styles'
import { translate } from '_helpers/translate'
import { AlertDialog } from 'components/AlertDialog'
import { inquiryPractice as inquiryPracticeSchema } from '_schema/inquiryPractice'
import { inquiries as inquiriesSchema } from '_schema/inquiry'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import { DragIndicator } from '@material-ui/icons'
import {sortableContainer, sortableElement, sortableHandle, arrayMove,} from 'react-sortable-hoc'


export const List = ({ match }) => {
  const {params: { inquiryId }} = match

  const classes = makeStyles({
    rowHead: {
      '& th': {
        textTransform: 'uppercase',
      },
    },
    dragHelper: {
      border: '1px solid silver',
      borderRadius: '7px',
      backgroundColor: '#D2DAFF',
      '& cellXS': {
        width: '50px',
        minWidth: '50px',
      },
      '& cellS': {
        width: '100px',
        minWidth: '100px',
      },
      '& cellM': {
        width: '250px',
        minWidth: '250px',
      },
    },
    cellXS: {
      width: '50px',
      minWidth: '50px',
    },
    cellS: {
      width: '100px',
      minWidth: '100px',
    },
    cellM: {
      width: '250px',
      minWidth: '250px',
    },
    cellAuto: {
      width: '100%',
    },
    icons: {
      display: 'flex',
      justifyContent: 'space-evenly',  marginBottom:-0.5, minHeight: 54, alignItems: 'center',
    }
  })();



  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [rowId, setRowId] = useState(null)
  const [saveOrd, setSaveOrd] = useState(0)
  const [state, setState] = useState({
    inquiryPractices: [],
    isProcessing: false,
    errorMessageSetters: {},
  })



  useEffect(() => {
    loadElements();
  }, [])

  useEffect(() => {
    if (saveOrd > 0) {
      handleSaveOrd();
    }
  }, [saveOrd]);


  const loadElements = () => {
    fetchData(
      inquiryPracticeSchema.listAll.replace('{inquiryId}', inquiryId),
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      setState({ ...state, inquiryPractices: response.content })
    });
  };

  const performSort = ({ oldIndex, newIndex }) => {
    setState(({ inquiryPractices }) => ({
      inquiryPractices: arrayMove(inquiryPractices, oldIndex, newIndex)
    }));

    setSaveOrd(saveOrd + 1);
  };


  const handleSaveOrd = () => {
    var ordData = [];
    // eslint-disable-next-line array-callback-return
    state.inquiryPractices.map((element, key) => {
      ordData.push({
        '@id': inquiryPracticeSchema.get.replace('{id}', element.id),
        ord: 1 + key
      });
    });

    setState((state) => ({ ...state, isProcessing: true }))
    dispatch(requestActions.start(state.url));

    fetchDataHandleAuthError(
      inquiriesSchema.set.replace('{id}', inquiryId),
      'PUT',
      {body: JSON.stringify(dataToObj({
        InquiryPractices: ordData
      }))},
      (response) => {
        setState((state) => ({ ...state, isProcessing: false }))
        notification('success',translate('T_RECORD_UPDATED'),translate('T_SAVED'));
      },
      (error) => {
        setState((state) => ({ ...state, isProcessing: false }))
        notification( 'error', error.response.violations.length ? translate('T_INCORRECT_FORM') : error.response.detail,error.response.title);
      },
      dispatch,
      requestConstants.FAILURE
    );
  };

  const handleOpenDialogDelete = (event, row) => {
    event.preventDefault();
    setOpen(true);
    setRowId(row);
  };

    // dialog z usunieciem - zamkniecie || akcja == CANCEL
  const handleDialogClose = () => {
    setOpen(false)
    setRowId(null)
  }

  // dialog z usunieciem - akcja == OK
  const handleDialogAgreeDelete = () => {
    const url = `${inquiryPracticeSchema.delete.replace('{id}', rowId)}`
    const method = 'DELETE'

    dispatch(requestActions.start(url))

    fetchDataHandleAuthError(
      url,
      method,
      { body: [] },
      () => {
        dispatch(requestActions.success())
        loadElements();
        notification('success',translate('T_RECORD_DELETED'),translate('T_SAVED'))
      },
      (error) => {
        notification('error',translate('T_INCORRECT_FORM'),translate('T_RECORD_DELETE_ERROR'))
      },
      dispatch,
      requestConstants.FAILURE
    )

    handleDialogClose()
  }


  const InquiryPracticeSortableList = sortableContainer(({ children }) => (
    <TableBody spacing={0}>
      {children}
    </TableBody>
  ));

  const DragHandle = sortableHandle(() => {
    return <DragIndicator />;
  });



  const InquiryPracticeSortableElement = sortableElement(({ value, element }) => {
    return (
      <TableRow className={classes.TableRow}>
        <TableCell >
          <DragHandle  />
        </TableCell>
        <TableCell>{element.Practice.title}</TableCell>
        <TableCell>{element.Practice.Game.title}</TableCell>
        <TableCell>
			<StatSwitch
			  value={element.stat}
			  fieldName="stat"
			  apiUrl={inquiryPracticeSchema.set.replace('{id}', element.id)}
			/>
        </TableCell>

        <TableCell className={classes.icons}>
          <Tooltip title={translate('T_EDIT')}>
            <Link
              component={RouterLink}
              to={routePath.inquiryPractices.edit(
                element.inquiryId,
                element.id
              )}
              rel="noreferrer"
            >
              <EditIcon />
            </Link>
          </Tooltip>
          <Tooltip title={translate('T_DELETE')}>
            <Link
              href="#"
              onClick={(event) => {
                handleOpenDialogDelete(event, element.id);
              }}
              rel="noreferrer"
            >
              <DeleteForeverIcon />
            </Link>
          </Tooltip>
        </TableCell>
      </TableRow>
    )
  })

  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>{translate('T_PRACTICE_TITLE')}</TableCell>
            <TableCell>{translate('T_GAME_TITLE')}</TableCell>
            <TableCell>{translate('T_STAT')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <InquiryPracticeSortableList
          onSortEnd={performSort}
          useDragHandle
          helperClass={classes.dragHelper}
        >
          {state.inquiryPractices.length === 0 && (
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{translate('T_NO_PRACTICES_ADDED')}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}

          {state.inquiryPractices.map((element, key) => (
            <InquiryPracticeSortableElement
              key={`item-${key}`}
              index={key}
              value={key}
              element={element}
            />
          ))}
        </InquiryPracticeSortableList>
      </Table>

      <br/>
      <br/>

      <div>
        <Button
          component={RouterLink}
          to={routePath.inquiryPractices.add(inquiryId)}
          variant="contained"
          color="primary"
        >
          {translate('T_INQUIRY_PRACTICE_ADD')}
        </Button>
      </div>

      <AlertDialog
        key="del-dlg"
        open={open}
        handleClose={handleDialogClose}
        handleAgree={() => {
          handleDialogAgreeDelete()
        }}
        title={translate('T_ALERT_DELETE_TITLE')}
        text={translate('T_ALERT_DELETE_TEXT')}
      />

    </React.Fragment>
  )
}
