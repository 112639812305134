import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import { validate } from '_helpers/validate'

export const StringType = ({
  name,
  type,
  label,
  hint,
  value,
  disabled,
  validators,
  setValue,
  setValidator,
  setErrorMessageSetter,
  fullWidth = true,
}) => {
  const [state, setState] = useState({
    value: typeof value !== 'undefined' ? value : '',
    error: { status: false, message: false },
  })

  const handleChange = (e) => {
    const value = type === 'number' ? parseInt(e.target.value) : e.target.value
    setState((state) => ({ ...state, value }))

    validator(value)
  }

  const validator = (value) => {
    const valid = validate(validators, value)
    setState((state) => ({
      ...state,
      error: { status: !valid.result, message: valid.message },
    }))
    setValue(name, !valid.result ? new Error(valid.message) : value)

    return valid.result
  }

  const setErrorMessage = (message) => {
    setState((state) => ({
      ...state,
      error: { status: true, message },
    }))
  }

  useEffect(() => {
    setValue(name, value)
    setValidator(name, validator)
    setErrorMessageSetter(name, setErrorMessage)
    // eslint-disable-next-line
  }, [])

  return (
    <TextField
      name={name}
      type={type === 'string' ? 'text' : type === 'textarea' ? 'text' : type}
      multiline={type === 'textarea' ? true : false}
      label={label + (validators.includes('required') ? ' *' : '')}
      value={state.value}
      disabled={disabled}
      onChange={handleChange}
      error={state.error.status}
      helperText={state.error.status ? state.error.message : hint}
      fullWidth={fullWidth}
    />
  )
}
