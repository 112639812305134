import React from 'react'
import PropTypes from 'prop-types'
import {
  Avatar,
  Button,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  CssBaseline,
  TextField,
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { Link as RouterLink } from 'react-router-dom'
import { Copyright } from 'components/Copyright'
import { useStyles } from './LoginStyles'
import { LangMenu } from 'components/menu/LangMenu'
import { translate } from '_helpers/translate'
import { routePath } from '_constants/routes.constants'

export const Login = ({ values, handlers, status, validate }) => {
  const classes = useStyles()

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {translate('T_EMAIL_TITLE')}
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handlers.handleSubmit}
        >
          <TextField
            error={status.submitted && !validate(values.login)}
            helperText={
              status.submitted && !validate(values.login)
                ? translate('T_LOGIN_ERROR')
                : null
            }
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="login"
            label={translate('T_EMAIL_LABEL')}
            name="login"
            autoComplete="login"
            autoFocus
            value={values.login}
            onChange={handlers.handleChange}
          />
          <TextField
            error={status.submitted && !values.password}
            helperText={
              status.submitted && !values.password
                ? translate('T_PASSWORD_ERROR')
                : null
            }
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={translate('T_PASSWORD_LABEL')}
            type="password"
            id="password"
            autoComplete="current-password"
            value={values.password}
            onChange={handlers.handleChange}
          />

          <Button
            disabled={status.isLogging}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {status.isLogging ? (
              <img
                className={classes.spinner}
                src="/images/logo.svg"
                alt="loading icon"
              />
            ) : (
              translate('T_LOG_IN')
            )}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                component={RouterLink}
                to={routePath.forgotpassword}
                variant="body2"
              >
                {translate('T_FORGOT_PASSWORD')}
              </Link>
            </Grid>
            <Grid item>
              <LangMenu asIcon={true} />
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

Login.propTypes = {
  values: PropTypes.shape({
    login: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
  handlers: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
  status: PropTypes.shape({
    submitted: PropTypes.bool,
    isLogging: PropTypes.bool,
  }).isRequired,
}
