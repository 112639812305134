function isObject(val) {
  return val instanceof Object
}

export function getProps(data = [], property) {
  let val = data
  property
    .split('.')
    .slice(1)
    .map((i) => {
      if (val) val = !val[i] ? null : val[i]
      return 0
    })
  return val
}

export const get = (object, path, value) => {
  const pathArray = Array.isArray(path) ? path : path.split('.').filter((key) => key)
  const pathArrayFlat = pathArray.flatMap((part) => (typeof part === 'string' ? part.split('.') : part))
  const checkValue = pathArrayFlat.reduce((obj, key) => obj && obj[key], object)
  return checkValue === undefined ? value : checkValue
}

export function setProps(shema) {
  let out = []
  function setmeprops(shema, inKey = '') {
    for (const [key, value] of Object.entries(shema)) {
      if (Array.isArray(value) || isObject(value)) {
        setmeprops(value, inKey + '.' + key)
      } else if (value !== '' && value !== null) {
        out.push({ name: value, key: inKey + '.' + key })
      }
    }
  }
  setmeprops(shema)
  return out
}

export function dataToObj(data) {
  let obj = {}
  Object.keys(data).map((name) => {
    if (data[name] === undefined) return 0
    const o = name.split('.')
    if (o.length === 4) {
      if (!obj[o[0]]) obj[o[0]] = {}
      if (!obj[o[0]][o[1]]) obj[o[0]][o[1]] = {}
      if (!obj[o[0]][o[1]][o[2]]) obj[o[0]][o[1]][o[2]] = {}
      if (!obj[o[0]][o[1]][o[2]][o[3]]) obj[o[0]][o[1]][o[2]][o[3]] = data[name]
    } else if (o.length === 3) {
      if (!obj[o[0]]) obj[o[0]] = {}
      if (!obj[o[0]][o[1]]) obj[o[0]][o[1]] = {}
      if (!obj[o[0]][o[1]][o[2]]) obj[o[0]][o[1]][o[2]] = data[name]
    } else if (o.length === 2) {
      if (!obj[o[0]]) obj[o[0]] = {}
      if (!obj[o[0]][o[1]]) obj[o[0]][o[1]] = data[name]
    } else {
      obj[o[0]] = data[name]
    }
    return 0
  })

  return obj
}
