import React, { useState } from 'react'

import Switch from '@material-ui/core/Switch'

import { dataToObj } from '_helpers/tableProps'
import { fetchData } from '_helpers/fetchData'

export const StatSwitch = (props) => {
  const [value, setValue] = useState(props.value)

  const prepareRequestData = (value) => {
    return { body: JSON.stringify(dataToObj({ [props.fieldName]: value })) }
  }

  const handleClick = (event) => {
    const isChecked = event.target.checked
    setValue(isChecked)

    fetchData(
      props.apiUrl,
      props.method ? props.method : 'PUT',
      prepareRequestData(isChecked),
      (resp) => resp.json(),
      false
    )
      .then((x) => new Promise((resolve) => setTimeout(() => resolve(x), 500))) // taki ładny delay
      .then((response) =>
        response[props.fieldName] !== undefined
          ? setValue(response[props.fieldName])
          : setValue(!isChecked)
      )
  }

  return <Switch size="small" checked={value} onChange={handleClick} />
}
