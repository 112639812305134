export const buildUrl = (domainUrl, data) => {
  var resultUrl = ''
  for (const [name, value] of Object.entries(data.queryParams)) {
    var urlPart = ''

    if (Array.isArray(value)) {
      if (value.length === 0) {
        continue
      } else {
        if (name.includes('[]')) {
          // eslint-disable-next-line no-loop-func
          value.forEach(function (itemVal) {
            urlPart += (urlPart === '' ? '' : '&') + name + '=' + itemVal
          })
        } else {
          urlPart += name + '=' + value.join(',')
        }
      }
    } else {
      urlPart += name + '=' + value
    }

    if (resultUrl === '') {
      resultUrl += '?' + urlPart
    } else {
      resultUrl += '&' + urlPart
    }
  }
  return domainUrl + resultUrl
}
