import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1.5em',
    marginBottom: '1.5em',
    display: 'block',
    width: '100%',
  },
  save: {
    marginTop: '1.5em',
  },
}))
