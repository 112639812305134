export const routePath = {
  home: '/home',
  //config: '/config',
  config: {
      main: '/config', 
      settings: '/settings',
      cokolwiek: '/cokolwiek'
  },
  login: '/login',
  forgotpassword: '/forgotPassword',
  resetpassword: '/security',

  superadmins: {
    main: '/superadmins',
    list: (p) => `${p ?? routePath.superadmins.main}/list`,
    edit: (id, p) => `${p ?? routePath.superadmins.main}/edit/${id}`,
    add: (p) => `${p ?? routePath.superadmins.main}/add`,
  },

  users: {
    main: '/users',
    list: (p) => `${p ?? routePath.users.main}/list`,
    edit: (id, p) => `${p ?? routePath.users.main}/edit/${id}`,
    add: (p, role) => `${p ?? routePath.users.main}/add/${role}`,
  },
  parentChilds: {
    main: '/users/list/parentChilds',
    list: (parentId, p) => `${p ?? routePath.parentChilds.main}/${parentId}`,
  },
  therapistTherapies: {
    main: '/users/list/therapies',
    list: (therapistId, p) =>
      `${p ?? routePath.therapistTherapies.main}/${therapistId}`,
    edit: (therapistId, id, p) =>
      `${
        p ?? routePath.therapistTherapies.main
      }/${therapistId}/list/edit/${id}`,
    add: (therapistId, p) =>
      `${p ?? routePath.therapistTherapies.main}/${therapistId}/list/add`,
  },

  childs: {
    main: '/childs',
    list: (p) => `${p ?? routePath.childs.main}/list`,
    edit: (id, p) => `${p ?? routePath.childs.main}/edit/${id}`,
    add: (p) => `${p ?? routePath.childs.main}/add`,
  },
  childTherapies: {
    main: '/childs/list/therapies',
    list: (childId, p) =>
      `${p ?? routePath.childTherapies.main}/${childId}/list`,
    edit: (childId, id, p) =>
      `${p ?? routePath.childTherapies.main}/${childId}/list/edit/${id}`,
    add: (childId, p) =>
      `${p ?? routePath.childTherapies.main}/${childId}/list/add`,
  },
  childInquiries: {
    main: '/childs/list/inquiries',
    list: (childId, p) =>
      `${p ?? routePath.childInquiries.main}/${childId}/list`,
    view: (childId, id, p) =>
      `${p ?? routePath.childInquiries.main}/${childId}/list/view/${id}`,
  },

  healthCenter: {
    main: '/healthCenters',
    list: (p) => `${p ?? routePath.healthCenter.main}/list`,
    edit: (id, p) => `${p ?? routePath.healthCenter.main}/edit/${id}`,
    add: (p) => `${p ?? routePath.healthCenter.main}/add`,
  },
  healthCenterChilds: {
    main: '/healthCenters/list/healthCenterChilds',
    list: (healthCenterId, p) =>
      `${p ?? routePath.healthCenterChilds.main}/${healthCenterId}`,
  },
  healthCenterTherapies: {
    main: '/healthCenters/list/healthCenterTherapies',
    list: (healthCenterId, p) =>
      `${p ?? routePath.healthCenterTherapies.main}/${healthCenterId}`,
  },

  profession: {
    main: '/professions',
    list: (p) => `${p ?? routePath.profession.main}/list`,
    edit: (id, p) => `${p ?? routePath.profession.main}/edit/${id}`,
    add: (p) => `${p ?? routePath.profession.main}/add`,
  },

  inquiries: {
    main: '/inquiries',
    list: (p) => `${p ?? routePath.inquiries.main}/list`,
    edit: (id, p) => `${p ?? routePath.inquiries.main}/edit/${id}`,
    add: (p) => `${p ?? routePath.inquiries.main}/add`,
  },
  inquiryQuestions: {
    main: '/inquiries/list/questions',
    list: (inquiryId, p) =>
      `${p ?? routePath.inquiryQuestions.main}/${inquiryId}/list`,
    edit: (inquiryId, id, p) =>
      `${p ?? routePath.inquiryQuestions.main}/${inquiryId}/list/edit/${id}`,
    add: (inquiryId, p) =>
      `${p ?? routePath.inquiryQuestions.main}/${inquiryId}/list/add`,
  },
  inquiryAnswers: {
    main: '/inquiries/list/answers',
    list: (questionId, p) =>
      `${p ?? routePath.inquiryAnswers.main}/${questionId}/list`,
    edit: (questionId, id, p) =>
      `${p ?? routePath.inquiryAnswers.main}/${questionId}/list/edit/${id}`,
    add: (questionId, p) =>
      `${p ?? routePath.inquiryAnswers.main}/${questionId}/list/add`,
  },
  inquiryVerdicts: {
    main: '/inquiries/list/verdicts',
    list: (inquiryId, p) =>
      `${p ?? routePath.inquiryVerdicts.main}/${inquiryId}/list`,
    edit: (inquiryId, id, p) =>
      `${p ?? routePath.inquiryVerdicts.main}/${inquiryId}/list/edit/${id}`,
    add: (inquiryId, p) =>
      `${p ?? routePath.inquiryVerdicts.main}/${inquiryId}/list/add`,
  },
  inquiryPractices: {
    main: '/inquiries/list/practices',
    list: (inquiryId, p) =>
      `${p ?? routePath.inquiryPractices.main}/${inquiryId}/list`,
    edit: (inquiryId, id, p) =>
      `${p ?? routePath.inquiryPractices.main}/${inquiryId}/list/edit/${id}`,
    add: (inquiryId, p) =>
      `${p ?? routePath.inquiryPractices.main}/${inquiryId}/list/add`,
  },


  therapies: {
    main: '/therapies',
    list: (p) => `${p ?? routePath.therapies.main}/list`,
    edit: (id, p) => `${p ?? routePath.therapies.main}/edit/${id}`,
    add: (p) => `${p ?? routePath.therapies.main}/add`,
  },

  games: {
    main: '/games',
    list: (p) => `${p ?? routePath.games.main}/list`,
    edit: (id, p) => `${p ?? routePath.games.main}/edit/${id}`,
    add: (p) => `${p ?? routePath.games.main}/add`,
  },
  elements: {
    main: '/elements',
    list: (p) => `${p ?? routePath.elements.main}/elements/list`,
    edit: (id, p) =>
      `${p ?? routePath.elements.main}/elements/edit/${id}`,
    add: (p) => `${p ?? routePath.elements.main}/elements/add`,
  },
  practices: {
    main: '/games/list/practices',
    list: (gameId, p) => `${p ?? routePath.practices.main}/${gameId}/list`,
    edit: (gameId, id, p) =>
      `${p ?? routePath.practices.main}/${gameId}/list/edit/${id}`,
    add: (gameId, p) => `${p ?? routePath.practices.main}/${gameId}/list/add`,
  },
  practiceCategories: {
    main: '/practiceCategories',
    list: (p) =>
      `${p ?? routePath.practiceCategories.main}/practiceCategories/list`,
    edit: (id, p) =>
      `${p ?? routePath.practiceCategories.main}/practiceCategories/edit/${id}`,
    add: (p) =>
      `${p ?? routePath.practiceCategories.main}/practiceCategories/add`,
  },
  gameEmotions: {
    main: '/gameEmotions',
    list: (p) => `${p ?? routePath.gameEmotions.main}/gameEmotions/list`,
    edit: (id, p) =>
      `${p ?? routePath.gameEmotions.main}/gameEmotions/edit/${id}`,
    add: (p) => `${p ?? routePath.gameEmotions.main}/gameEmotions/add`,
  },
}
