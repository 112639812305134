import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { fetchData } from '_helpers/fetchData'
import { translate } from '_helpers/translate'
import { dataToObj } from '_helpers/tableProps'
import { notification } from '_helpers/notification'
import { requestActions } from '_actions'
import { requestConstants } from '_constants'
import { makeStyles } from '@material-ui/core/styles'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import { DragIndicator } from '@material-ui/icons'
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
  arrayMove,
} from 'react-sortable-hoc'
import { practice as schema } from '_schema/practice'

export const ChildTherapyPracticesList = ({ data, passed }) => {
  const useStyles = makeStyles({
    rowHead: {
      '& th': {
        textTransform: 'uppercase',
      },
    },
    dragHelper: {
      border: '1px solid silver',
      borderRadius: '7px',
      backgroundColor: '#D2DAFF',
      '& cellXS': {
        width: '50px',
        minWidth: '50px',
      },
      '& cellS': {
        width: '100px',
        minWidth: '100px',
      },
      '& cellM': {
        width: '250px',
        minWidth: '250px',
      },
    },
    cellXS: {
      width: '50px',
      minWidth: '50px',
    },
    cellS: {
      width: '100px',
      minWidth: '100px',
    },
    cellM: {
      width: '250px',
      minWidth: '250px',
    },
    cellAuto: {
      width: '100%',
    },
  })

  const classes = useStyles()
  const [saveOrd, setSaveOrd] = useState(0)
  const dispatch = useDispatch()
  const therapy = data

  const [state, setState] = useState({
    items: [],
    movedItemId: null,
    isProcessing: false,
    errorMessageSetters: {},
  })

  useEffect(() => {
    loadPracticesList()
  }, [])

  useEffect(() => {
    if (saveOrd > 0) {
      handleSaveOrd()
    }
  }, [saveOrd])

  const loadPracticesList = () => {
    fetchData(
      schema.listAllByTherapy.replace('{therapyId}', therapy.id) +
        '?order[ord]=ASC&passed=' +
        passed,
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      setState({ ...state, items: response.content })
    })
  }

  const performSort = ({ oldIndex, newIndex }) => {
    setState(({ items }) => ({
      movedItemId: state.items[oldIndex].id,
      items: arrayMove(items, oldIndex, newIndex),
    }))

    setSaveOrd(saveOrd + 1)
  }

  const handleSaveOrd = () => {
    var ordData = []

    // eslint-disable-next-line array-callback-return
    state.items.map((element, key) => {
      ordData.push({
        id: element.id,
        newOrd: 1 + key,
      })
    })

    const requestData = JSON.stringify(
      dataToObj({
        ordData: ordData,
      })
    )

    setState((state) => ({ ...state, isProcessing: true }))
    dispatch(requestActions.start(state.url))

    fetchDataHandleAuthError(
      schema.set.replace('{id}', state.movedItemId),
      'PUT',
      { body: requestData },
      (response) => {
        setState((state) => ({ ...state, isProcessing: false }))
        notification(
          'success',
          translate('T_RECORD_UPDATED'),
          translate('T_SAVED')
        )
      },
      (error) => {
        setState((state) => ({ ...state, isProcessing: false }))
        notification(
          'error',
          error.response.violations.length
            ? translate('T_INCORRECT_FORM')
            : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      requestConstants.FAILURE
    )
  }

  const PracticeSortableList = sortableContainer(({ children }) => (
    <TableBody className={classes.sortableList} spacing={0}>
      {children}
    </TableBody>
  ))

  const DragHandle = sortableHandle(() => {
    return <DragIndicator />
  })

  const PracticeSortableElement = sortableElement(({ value, element }) => {
    return (
      <TableRow>
        <TableCell className={classes.cellXS}>
          <DragHandle className={classes.subElement} />
        </TableCell>
        <TableCell className={classes.cellXS}>{element.id}</TableCell>
        <TableCell className={classes.cellM}>{element.Game.title}</TableCell>
        <TableCell className={classes.cellM}>{element.stagesCount}</TableCell>
        <TableCell className={classes.cellM}>{element.title}</TableCell>
        <TableCell className={classes.cellAuto}>{element.text}</TableCell>
      </TableRow>
    )
  })

  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow className={classes.rowHead}>
            <TableCell className={classes.cellXS}></TableCell>
            <TableCell className={classes.cellXS}>
              {translate('T_ID')}
            </TableCell>
            <TableCell className={classes.cellM}>
              {translate('T_GAME_TITLE')}
            </TableCell>
            <TableCell className={classes.cellM}>
              {translate('T_STAGES_COUNT')}
            </TableCell>
            <TableCell className={classes.cellM}>
              {translate('T_GAME_PRACTICE_TITLE')}
            </TableCell>
            <TableCell className={classes.cellAuto}>
              {translate('T_DESCRIPTION')}
            </TableCell>
          </TableRow>
        </TableHead>

        <PracticeSortableList
          onSortEnd={performSort}
          useDragHandle
          helperClass={classes.dragHelper}
        >
          {state.items.map((element, key) => (
            <PracticeSortableElement
              key={`item-${key}`}
              index={key}
              value={key}
              element={element}
            />
          ))}
        </PracticeSortableList>
      </Table>
    </React.Fragment>
  )
}
