import React, { useState, useEffect } from 'react'
import { fetchData } from '_helpers/fetchData'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { useSelector, useDispatch } from 'react-redux'
import TableList from './TableList'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { makeStyles } from '@material-ui/core/styles'
import { setProps } from '_helpers/tableProps'
import { translate } from '_helpers/translate'
import { therapies as schema } from '_schema/therapy'
import { users as userSchema } from '_schema/user'

const useStyles = makeStyles({
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-18px',
    marginBottom: '20px',
  },
})

const typingTimeout = 1000

const listCols = () => {
  return {
    'Child.firstName': translate('T_CHILD_FIRST_NAME'),
    'Child.lastName': translate('T_CHILD_LAST_NAME'),
    createdAt: translate('T_THERAPY_START_DATE'),
    therapistConfirmed: translate('T_THERAPIST_CONFIRMED'),
    parentConfirmed: translate('T_PARENT_CONFIRMED'),
    displayCollumn: 5, //extra, tell how many kolumns show
  }
}

const mainData = () => {
  return setProps(listCols())
}

export const List = ({ match }) => {
  const {
    params: { therapistId },
  } = match

  const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}${schema.therapistTherapiesList}`.replace(
    '{therapistId}',
    therapistId
  )

  const listqueryParams =
    localStorage.listUserTherapiesQueryParams === undefined
      ? {
          search: '',

          page: 1,
          perPage: 12,
          'order[name]': 'ASC',
        }
      : JSON.parse(localStorage.listUserTherapiesQueryParams)

  const classes = useStyles()
  const auth = useSelector((state) => state.auth)
  const [queryParams, setQueryParams] = useState(listqueryParams)
  const [typingState, setTypingState] = useState({ timeoutId: null })
  const [searchText, setSearchText] = useState(
    listqueryParams.search ? listqueryParams.search : ''
  )

  const [therapistData, setTherapistData] = useState(null)

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)

  useEffect(() => {
    localStorage.listUserTherapiesQueryParams = JSON.stringify(queryParams)
    return () => {
      // cleanup
    }
  }, [queryParams])

  useEffect(() => {
    // zaciągnięcie danych dziecka
    fetchData(
      userSchema.get.replace('{id}', therapistId),
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      setTherapistData(response)
    })
  }, [therapistId]) // bez parametrów odpala sie tylko raz

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  return (
    <React.Fragment>
      <div>
        Terapeuta:{' '}
        <b>
          {therapistData?.firstName} {therapistData?.lastName}
        </b>{' '}
        <br />
      </div>

      <div className={classes.gridParent}>
        <TextField
          label={translate('T_SEARCH')}
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <TableList
        data={
          res.state.data
            ? res.state.data.content
              ? res.state.data.content
              : []
            : []
        }
        isLoading={res.state.isLoading}
        error={res.state.error}
        mainData={mainData()}
        expandData={false}
        dataLength={res.state.data?.totalItems}
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />

    </React.Fragment>
  )
}
