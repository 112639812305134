import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { authActions } from '_actions'
import { Login } from './Login'

export const LoginContainer = () => {
  const [state, setState] = useState({
    login: '',
    password: '',
    submitted: false,
  })
  const dispatch = useDispatch()

  function validate(login) {
    if (login && login.length >= 4) return true
    else return false
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((state) => ({ ...state, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setState((state) => ({ ...state, submitted: true }))
    const { login, password } = state

    if (validate(login) && password) {
      dispatch(authActions.login(login, password))
    }
  }

  const isLogging = useSelector((state) => state.auth.isLogging)
  const { login, password, submitted } = state

  return (
    <Login
      values={{ login, password }}
      handlers={{ handleChange, handleSubmit }}
      status={{ submitted, isLogging }}
      validate={validate}
    />
  )
}
