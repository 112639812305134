import { combineReducers } from 'redux'
import { authReducer } from './auth.reducer'
import { userReducer } from './user.reducer'
import { gameReducer } from './game.reducer'

const reducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  game: gameReducer,
})

export default reducers
