import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import TableList from './TableList'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { makeStyles } from '@material-ui/core/styles'
import { setProps } from '_helpers/tableProps'
import { translate } from '_helpers/translate'
import * as schema from '_schema/profession'
import { routePath } from '_constants/routes.constants.js'

const useStyles = makeStyles({
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-18px',
    marginBottom: '20px',
  },
})

const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}${schema.profession.list}`
const typingTimeout = 1000

const listCols = () => {
  return {
    title: translate('T_NAME'),
    stat: translate('T_STAT'),
    displayCollumn: 2, //extra, tell how many kolumns show
  }
}

const mainData = () => {
  return setProps(listCols())
}

export const List = () => {
  const listqueryParams =
    localStorage.listProfessionQueryParams === undefined
      ? {
          search: '',
          page: 1,
          perPage: 12,
          'order[name]': 'ASC',
        }
      : JSON.parse(localStorage.listProfessionQueryParams)

  const classes = useStyles()
  const auth = useSelector((state) => state.auth)
  const [queryParams, setQueryParams] = useState(listqueryParams)
  const [typingState, setTypingState] = useState({ timeoutId: null })
  const [searchText, setSearchText] = useState(listqueryParams.search ?? '')


  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)

  useEffect(() => {
    localStorage.listProfessionQueryParams = JSON.stringify(queryParams)
    return () => {
      // cleanup
    }
  }, [queryParams])

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }


  return (
    <React.Fragment>
      <div className={classes.gridParent}>

        <TextField
          label={translate('T_SEARCH')}
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <TableList
        data={res.state.data ? res.state.data?.content : []}
        isLoading={res.state.isLoading}
        error={res.state.error}
        mainData={mainData()}
        expandData={false}
        dataLength={res.state.data?.totalItems}
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
      <div>
        <Button
          component={RouterLink}
          to={routePath.profession.add()}
          variant="contained"
          color="primary"
        >
          {translate('T_PROFESSION_ADD')}
        </Button>
      </div>
    </React.Fragment>
  )
}
