const languages = { pl: { title: 'Polski' }, en: { title: 'English' } }
const defaultLang = process.env.REACT_APP_LOCALE

const set = (lang) => {
  if (languages[lang]) localStorage.setItem('language', lang)
}

const current = () => {
  return localStorage.getItem('language') ?? defaultLang
}

export const langService = {
  languages,
  set,
  current,
}
