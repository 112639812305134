import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { requestActions } from '_actions'
import { requestConstants } from '_constants'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { fetchData } from '_helpers/fetchData'
import { notification } from '_helpers/notification'
import { StringType } from 'components/form/StringType'
import { SelectType } from 'components/form/SelectType'
import { CheckboxType } from 'components/form/CheckboxType'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Save } from '@material-ui/icons'
import { dataToObj } from '_helpers/tableProps'
import { getProps } from '_helpers/tableProps'
import { translate } from '_helpers/translate'
import { useStyles } from 'components/form/FormStyles'
import { useHistory } from 'react-router-dom'
import * as userSchema from '_schema/user'
import * as provinceSchema from '_schema/province'
import * as healthCenterSchema from '_schema/healthCenter'
import * as professionSchema from '_schema/profession'
import { routePath } from '_constants/routes.constants.js'

import moment from 'moment'

export const Addto = ({ match, dataResource }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    params: { userId, role },
  } = match

  const userRole = role ? role : null
  const url = userId ? userSchema.users.superadminset : userSchema.users.add
  const method = userId ? 'PUT' : 'POST'
  const data = userId ? dataResource : {'Roles': [], 'Professions': []}

  const [state, setState] = useState({
    values: {},
    errors: {},
    validators: {},
    errorMessageSetters: {},
    invalid: 0,
    isProcessing: false,
    url: url.includes('{id}') ? url.replace('{id}', userId) : url,
    provinces: [],
    healthCenters: [],
    professions: [],
  })

  useEffect(() => {
    fetchData(
      provinceSchema.province.list,
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      setState((state) => ({ ...state, provinces: response.content }))
    })

    fetchData(
      healthCenterSchema.healthCenter.list,
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      setState((state) => ({ ...state, healthCenters: response.content }))
    })

    fetchData(
      professionSchema.profession.list,
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      setState((state) => ({ ...state, professions: response.content }))
    })
  }, []) // bez parametrów odpala sie tylko raz

  const fields = {}
  fields.email = {
    type: 'string',
    validate: ['required'],
    label: translate('T_EMAIL_LABEL'),
    hint: translate('T_EMAIL_HINT'),
  }
  fields.login = {
    type: 'string',
    validate: ['required'],
    label: translate('T_LOGIN_LABEL'),
    hint: translate('T_LOGIN_HINT'),
  }
  fields.firstName = {
    type: 'string',
    validate: ['required'],
    label: translate('T_FIRST_NAME_LABEL'),
    hint: translate('T_FIRST_NAME_HINT'),
  }
  fields.lastName = {
    type: 'string',
    validate: ['required'],
    label: translate('T_LAST_NAME_LABEL'),
    hint: translate('T_LAST_NAME_HINT'),
  }
  fields.phone = {
    type: 'string',
    validate: ['required'],
    label: translate('T_PHONE_LABEL'),
    hint: translate('T_PHONE_HINT'),
  }
  if (
    (userRole != null && userRole === 'ROLE_THERAPIST')
    ||  data.Roles.indexOf('ROLE_THERAPIST') >= 0
  ) {
    fields.Province = {
      type: 'select',
      validate: ['required'],
      resource: '.Province.id',
      value: data.Province ? provinceSchema.province.get.replace('{id}', data.Province.id) : '',
      options: state.provinces.map((row)=>{
        return { ...row,
          id: provinceSchema.province.get.replace('{id}', row.id),
        }
      }),
      label: translate('T_PROVINCE_LABEL'),
      hint: translate('T_PROVINCE_HINT'),
    }
  }
  if (
    userRole != null
    || data.Roles.indexOf('ROLE_MANAGER') >= 0
    || data.Roles.indexOf('ROLE_THERAPIST') >= 0
  ) {
    fields.HealthCenter = {
      type: 'select',
      validate: ['required'],
      value: data.HealthCenter ? healthCenterSchema.healthCenter.get.replace('{id}', data.HealthCenter.id) : '',
      options: state.healthCenters.map((row) => {return { ...row,
          id: healthCenterSchema.healthCenter.get.replace('{id}', row.id),
          title: row.name
      }}),
      label: translate('T_HEALTH_CENTER_LABEL'),
      hint: translate('T_HEALTH_CENTER_HINT'),
      addEmpty: true,
    }
  }

  if (
    userRole === 'ROLE_THERAPIST'
    || data.Roles.indexOf('ROLE_THERAPIST') >= 0
  ) {
    fields.Professions = {
      type: 'select',
      multiple: true,
      validate: ['required'],
      value: getProps(data, '.Professions').map((row) => {
          return '/professions/' + row.id
      }),
      options: state.professions.map((row) => {
        return { ...row, id: '/professions/' + row.id }
      }),
      label: translate('T_PROFESSIONS_LABEL'),
      hint: translate('T_PROFESSIONS_HINT'),
    }
  }
  fields.city = {
    type: 'string',
    validate: ['required'],
    label: translate('T_CITY_LABEL'),
    hint: translate('T_CITY_HINT'),
  }
  
  if (data.Roles.indexOf('ROLE_PARENT') >= 0) {
    fields.paymentValidUntil = {
      type: 'date',
      validate: [],
      label: translate('T_PAYMENT_VALID_UNTIL_LABEL'),
      hint: translate('T_PAYMENT_VALID_UNTIL_HINT'),
    }      
  }

  fields.stat = {
    type: 'check',
    validate: [],
    label: translate('T_STATUS_USER_LABEL'),
  }
  fields.accepted = {
    type: 'check',
    validate: [],
    label: translate('T_ACCEPTED_USER_LABEL'),
  }

  if (userRole != null) {
    fields.plainPassword = {
      type: 'password',
      validate: ['required'],
      label: translate('T_PASSWORD_LABEL'),
      hint: translate('T_PASSWORD_HINT'),
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (
      Object.keys(state.validators).length &&
      !Object.keys(state.validators).reduce(
        (status, name) =>
          state.validators[name].validator(
            ...Object.keys(state.values)
              .filter((item) => state.validators[name].values.includes(item))
              .map((item) => state.values[item])
          ) && status,
        true
      )
    ) {
      return
    }

    setState((state) => ({ ...state, isProcessing: true }))
    dispatch(requestActions.start(state.url))

    var requestValues = state.values;
    if (userRole && userRole === 'ROLE_MANAGER') {
      requestValues.isManager = true;
    }

    if (userRole && userRole === 'ROLE_THERAPIST') {
      requestValues.isTherapist = true;
    }

    requestValues.paymentValidUntil = Date.parse(requestValues.paymentValidUntil) > 0 ? requestValues.paymentValidUntil : null;
    
    fetchDataHandleAuthError(
      state.url,
      method,
      { body: JSON.stringify(dataToObj(requestValues)) },
      () => {
        setState((state) => ({ ...state, isProcessing: false }))
        dispatch(requestActions.success())
        notification(
          'success',
          method === 'PUT'
            ? translate('T_RECORD_UPDATED')
            : translate('T_RECORD_ADDED'),
          translate('T_SAVED')
        )
        handleSuccess && handleSuccess(state.values)
      },
      (error) => {
        setState((state) => ({ ...state, isProcessing: false }))
        error.response.violations.map((item) =>
          state.errorMessageSetters[item.propertyPath](translate(item.message))
        )
        notification(
          'error',
          error.response.violations.length
            ? translate('T_INCORRECT_FORM')
            : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      requestConstants.FAILURE
    )
  }

  const handleSuccess = (val) => {
    //check if admin change own profile

    //history.push(routePath.users.list())
  }

  const setValue = (name, value) => {
    const isInvalid = value instanceof Error
    setState((state) => ({
      ...state,
      values: { ...state.values, [name]: !isInvalid && value },
      errors: { ...state.errors, [name]: isInvalid },
      invalid:
        state.invalid +
        (!!state.errors[name] === !isInvalid && -1 + isInvalid * 2),
    }))
  }

  const setValidator = (name, validator, values) => {
    setState((state) => ({
      ...state,
      validators: {
        ...state.validators,
        [name]: { validator, values: values || [name] },
      },
    }))
  }

  const setErrorMessageSetter = (name, errorMessageSetter) => {
    setState((state) => ({
      ...state,
      errorMessageSetters: {
        ...state.errorMessageSetters,
        [name]: errorMessageSetter,
      },
    }))
  }

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <div>
          Rola: <b>{translate(data.Roles.join(', '))} {userRole}</b>
        </div>
        <form onSubmit={handleSubmit} className={classes.root}>
          <Grid container spacing={3}>
            {Object.keys(fields).map((name, key) => {
              if (!fields[name]) return null

              return (
                <Grid item xs={4} key={`${key}_grid`}>
                  {(fields[name].type === 'string' || fields[name].type === 'password') && (
                    <StringType
                      key={key}
                      name={fields[name].name ? fields[name].name : name}
                      label={fields[name].label}
                      type={fields[name].type}
                      hint={fields[name].hint}
                      value={
                        data &&
                        (fields[name]['resource']
                          ? getProps(data, fields[name]['resource'])
                          : data[name]
                          ? data[name]
                          : '')
                      }
                      disabled={
                        fields[name].disabled === true
                          ? true
                          : state.isProcessing
                      }
                      validators={fields[name].validate || []}
                      setValue={setValue}
                      setValidator={setValidator}
                      setErrorMessageSetter={setErrorMessageSetter}
                    />
                  )}

                  {fields[name].type === 'check' && (
                    <CheckboxType
                      key={key}
                      name={fields[name].name ? fields[name].name : name}
                      label={fields[name].label}
                      hint={fields[name].hint}
                      checked={
                        data &&
                        (fields[name]['resource']
                          ? getProps(data, fields[name]['resource'])
                          : data[name]
                          ? data[name]
                          : false)
                      }
                      disabled={
                        fields[name].disabled === true
                          ? true
                          : state.isProcessing
                      }
                      validators={fields[name].validate || []}
                      setValue={setValue}
                      setValidator={setValidator}
                      setErrorMessageSetter={setErrorMessageSetter}
                    />
                  )}

                  {fields[name].type === 'select' && (
                    <SelectType
                      value={fields[name].value}
                      options={fields[name].options}
                      addEmpty={fields[name].addEmpty}
                      multiple={fields[name].multiple}
                      name={name}
                      validators={fields[name].validate || []}
                      label={fields[name].label}
                      hint={fields[name].hint}
                      setValue={setValue}
                      setValidator={setValidator}
                      setErrorMessageSetter={setErrorMessageSetter}
                    />
                  )}

                  {fields[name].type === 'date' && (
                    <StringType
                      key={key}
                      name={fields[name].name ? fields[name].name : name}
                      label={fields[name].label}
                      type={fields[name].type}
                      hint={fields[name].hint}
                      value={(() => {
                        const dateObj =
                          data &&
                          (fields[name]['resource']
                            ? getProps(data, fields[name]['resource'])
                            : data[name]
                            ? data[name]
                            : '')
                        return moment(dateObj).format('YYYY-MM-DD')
                      })()}
                      disabled={
                        fields[name].disabled === true
                          ? true
                          : state.isProcessing
                      }
                      validators={fields[name].validate || []}
                      setValue={setValue}
                      setValidator={setValidator}
                      setErrorMessageSetter={setErrorMessageSetter}
                    />
                  )}
          
                </Grid>
              )
            })}

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<Save />}
                className={classes.save}
                disabled={state.isProcessing || !!state.invalid}
              >
                {userId ? translate('T_SAVE') : translate('T_ADD_SAVE')}
              </Button>
            </Grid>
            
            <>
                <div className="payment-list">
                   <h4 className="login-content__link">Lista płatności</h4>
                   {data.Payments?.map(({ payuOrderId, createdAt, orderPrice, payuResult, invoiceAddress, invoicePostcode, invoiceCity, productTitle}) => {
                     return (
                       <div key={payuOrderId}>
                         {new Date(createdAt).toLocaleDateString('pl-PL')} - <b>{orderPrice}zł</b> - 
                         {' PayU: '} {payuOrderId} - {' '}
                         
                         <b>{productTitle}</b> - {' '}
                         
                         {payuResult === 'COMPLETED' ? 'potwierdzona' : 'niepotwierdzona'}
                       </div>
                     )
                   })}
                </div>  
            </>
            
          </Grid>
        </form>
      </Grid>
    </React.Fragment>
  )
}
