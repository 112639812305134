import { tokenVerify } from './tokenVerify'
import { tokenRoles } from './tokenRoles'

export const hasRole = (role) => {
  const token = localStorage.getItem('token')
  const isLogged = tokenVerify(token)

  if (isLogged) {
    return tokenRoles(token, role)
  }
  return false
}
