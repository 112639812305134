import React, { useState } from 'react'
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined'
import { Menu, MenuItem } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { Link as RouterLink } from 'react-router-dom'
import { translate } from '_helpers/translate'
import { langService } from '_services'
import { useTheme } from 'components/ThemeProvider'

export const LangMenu = ({ asIcon }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const languages = langService.languages

  const themeState = useTheme()

  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = (lang = null) => {
    setAnchorEl(null)
    if (lang) {
      const currentLang = langService.current()
      if (currentLang !== lang) {
        langService.set(lang)
        themeState.setLang(lang)
      }
    }
  }

  return (
    <>
      <Tooltip title={translate('T_SELECT_LANGUAGE')}>
        <IconButton
          aria-label="select language"
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleMenu}
          color={asIcon ? 'primary' : 'inherit'}
          style={asIcon ? { padding: 0 } : {}}
          size={asIcon ? 'small' : 'medium'}
        >
          <PublicOutlinedIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={() => handleClose()}
      >
        {Object.keys(languages).map((lg, i) => {
          return (
            <MenuItem
              onClick={() => handleClose(lg)}
              key={`lm-${i}`}
              component={RouterLink}
              to={window.location.pathname}
            >
              {languages[lg].title}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
