import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardActions, CardContent, Typography } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useStyles } from './AccessDeniedStyles'

export const AccessDenied = () => {
  const classes = useStyles()

  return (
    <div className={classes.flex}>
      <Card className={classes.card} raised="true">
        <CardContent>
          <Typography className={classes.pos} variant="h5" component="h2">
            Access Denied
          </Typography>

          <Typography variant="body2" component="p">
            Looks like you&apos;ve followed a link or entered a URL that access
            denied on this site.
          </Typography>
        </CardContent>
        <CardActions>
          <Link className={classes.link} color="secondary" to={'/'}>
            <ArrowBackIosIcon /> Back to home
          </Link>
        </CardActions>
      </Card>
    </div>
  )
}
