import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { translate } from '_helpers/translate'
import Divider from '@material-ui/core/Divider'

export const AlertDialog = ({
  open,
  handleClose,
  handleAgree,
  title = '',
  text = '',
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <Divider component="div" />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate('T_NO')}
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            {translate('T_YES')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
