import { tokenExpiration } from './tokenExpiration'

export const tokenVerify = (token) => {
  if (!token) {
    return null
  }
  const now = new Date()
  const exp = tokenExpiration(token)

  if (now > exp) {
    return false
  }

  return true
}
