import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { Copyright } from 'components/Copyright'
import { HomeAppBar } from 'components/bar/HomeAppBar'
import HomeBoxes from './HomeBoxes'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))

export const Home = () => {
  const classes = useStyles()

  const boxes = [
    {
      match: `superadministrator/list`,
      queryParams: {
        perPage: '6',
      },
      title: 'Administracja',
      keys: ['login', 'firstName', 'lastName', 'email'],
      header: ['LOGIN', 'IMIĘ', 'NAZWISKO', 'EMAIL'],
      linkMore: '/superadmins/list',
      md: 6,
    },
    {
      match: `user/list`,
      queryParams: {
        perPage: '6',
      },
      title: 'Użytkownicy',
      keys: ['login', 'firstName', 'lastName', 'email'],
      header: ['LOGIN', 'IMIĘ', 'NAZWISKO', 'EMAIL'],
      linkMore: '/users/list',
      md: 6,
    },
    {
      match: `child/list`,
      queryParams: {
        perPage: '6',
      },
      title: 'Dzieci',
      keys: ['firstName', 'lastName', 'gender', 'birthDate'],
      header: ['IMIĘ', 'NAZWISKO', 'PŁEĆ', 'DATA URODZENIA'],
      linkMore: '/childs/list',
      md: 6,
    },
    {
      match: `healthCenter/list`,
      queryParams: {
        perPage: '6',
      },
      title: 'Ośrodki',
      keys: ['name', 'city', 'Province.title', 'phone'],
      header: ['NAZWA', 'MIASTO', 'WOJEWÓDŹTWO', 'TELEFON'],
      linkMore: '/healthCenters/list',
      md: 6,
    },
  ]

  return (
    <div className={classes.root}>
      <CssBaseline />
      <HomeAppBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <HomeBoxes {...boxes} />
        </Container>
        <Copyright />
      </main>
    </div>
  )
}
