import { matchPath } from 'react-router'

export const generateBreadcrumbs = (path, routes) =>
  path
    .replace(/^\//, '')
    .split('/')
    .reduce((paths, path) => paths.concat(paths.slice(-1) + '/' + path), [])
    .map((path) => {
      for (let i = 0; i < routes.length; i++) {
        if ((routes[i].path || routes[i].from).slice(-1) === '*') {
          return null
        }

        if (
          matchPath(path, {
            path: routes[i].path || routes[i].from,
            exact: routes[i].exact,
          })
        ) {
          return { path, title: routes[i].title }
        }
      }
      return null
    })
    .filter((item) => item !== null)
