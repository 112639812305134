import React from 'react'
import { NavLink } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '& .MuiListItemText-primary': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: 13.5,
    },
  },
  active: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.contrastText,
    },
  },
  ico: {
    minWidth: 39
  }
}))

export const SideMenu = ({ items }) => {
  const classes = useStyles()
  return (
    <>
      {items.map((item, i) => {
        const IconComponent = item.icon
        return (
          <NavLink
            key={`sm-${i}`}
            to={item.path}
            className={classes.link}
            activeClassName={classes.active}

            exact={!!item.exact}
          >
            <ListItem button>
              <ListItemIcon className={classes.ico}>
                <IconComponent />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          </NavLink>
        )
      })}
    </>
  )
}
